import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { stockCreate } from "../../../api/stocks";
import {
  ERRORMESSAGE,
  errorToastOptions,
  stockDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useBudget from "../../../hooks/useBudget";
import useStocks from "../../../hooks/useStocks";
import {
  formatAcountSelectData,
  formatDepartmentSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import useStockProduct from "../../../hooks/useStockProducts";
import useSubCategories from "../../../hooks/useSubCategories";

interface IAddStockProps {
  closeModal: () => void;
}

const AddStock = ({ closeModal }: IAddStockProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [stock, setStock] = useState(stockDefaultData);

  const { stockProducts } = useStockProduct()
  const {getCategories, categories, subCategories, getSubCategories} = useSubCategories()

  useEffect(()=> {getCategories(); getSubCategories()}, [])


  const { refresh } = useStocks();
  const { budgets } = useBudget();

  const validator = new Validator();
  const {
    cashAccounts,
    loading,
    formattedAssetAccounts,
    getCashAccount,
    getAssetAccount,
    expenseAccounts,
    getExpenseAccount,

  } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCashAccount(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAssetAccount(), []);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setStock({
        ...stock,
        ...{ [name]: { ...stock[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setStock({
        ...stock,
        ...{ [name]: { ...stock[name], error: true, data: value } },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setStock({
        ...stock,
        ...{ [name]: { ...stock[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setStock({
        ...stock,
        ...{ [name]: { ...stock[name], error: true, data: value } },
      });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(stock, setStock)) {
      setFormLoading(true);

      // const data = {
      //   name: stock.name.data,
      //   quantity: stock.quantity.data,
      //   total_price: stock.total_price.data,
      //   bought_on: stock.bought_on.data,
      //   account_chart_id: stock.account_chart_id.data
      // }

      const data = getFormData(stock);

      // console.log(data); return;

      try {
        const newProject = await stockCreate(data, "stock/create");
        if (newProject.data.success === true) {
          toast.success("New stock created successfully", successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(newProject.data.data),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false);
      }
    }
  };
  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={stockProducts?.map((res: any) => ({
              value: res?.id,
              label: res?.name,
            }))}
            name="name"
            label="Name"
            id="name"
            required
            handleChange={handleSelectChange}
          />
        </div>
        {/* <div className="col-sm-6">
          <Input
            name="name"
            label="Name"
            handleChange={handleChange}
            value={stock.name.data}
            error={stock.name.error}
            errorMessage="Please enter the stock name"
            required
          />
        </div> */}
        <div className="col-sm-6">
          <Input
            name="quantity"
            label="Quantity"
            type="number"
            handleChange={handleChange}
            value={stock.quantity.data}
            error={stock.quantity.error}
            errorMessage="Please enter the stock quantity"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="total_price"
            label="Total Price"
            handleChange={handleChange}
            value={stock.total_price.data}
            error={stock.total_price.error}
            errorMessage="Please enter the stock total price"
            required
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            name="bought_on"
            label="Date bought"
            handleChange={handleSelectChange}
            required
            value={stock.bought_on.data}
            error={stock.bought_on.error}
            errorMessage="Please select the date the stock was bought on"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={formatDepartmentSelectData(budgets)}
            label="Budget"
            name="budget_id"
            id="budget_id"
            required
            handleChange={handleSelectChange}
            value={stock.budget_id.data}
            error={stock.budget_id.error}
            errorMessage="Please select the budget"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={categories[0]?.sub_categories?.map((res: any) => ({
              value: res?.id,
              label: res?.sub_cat_name,
            }))}
            label="Fund Account"
            name="account_chart_id"
            id="account_chart_id"
            required
            handleChange={handleSelectChange}
            value={stock.account_chart_id.data}
            error={stock.account_chart_id.error}
            errorMessage="Please select the fund account"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={expenseAccounts}
            label="Stock Expense Account"
            name="stock_account"
            id="stock_account"
            required
            handleChange={handleSelectChange}
            value={stock.stock_account.data}
            error={stock.stock_account.error}
            errorMessage="Please select the stock account"
          />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddStock;
