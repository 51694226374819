import DashboardWrapper from "../../components/DashboardWrapper";
import ReportsMenuItemList from "../../components/reports/reportsMenu/reportsMenuList";
import Breadcrumbs from "../../parts/Breadcrumbs";

const ReportsMenu = () => {
    return (
        <DashboardWrapper>
          <>
            <Breadcrumbs
              title="Reports Menu"
            />
            <ReportsMenuItemList />
          </>
        </DashboardWrapper>
      );
}
export default ReportsMenu