import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  goodsRecievedDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useRequisition from "../../../hooks/useRequisition";
import useUsers from "../../../hooks/useUsers";
import useSuppliers from "../../../hooks/useSuppliers";
import Input, { InputNoLabel } from "../../global/Input";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import {
  formatAcountSelectData,
  formatbidSelectData,
  formatPoSelectData,
  formatSupplierSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";

interface IAddGoodsRecievedProps {
  closeModal: () => void;
  refresh: () => void;
  id?: number | null;
}

const AddGoodsRecieved = ({ closeModal, refresh }: IAddGoodsRecievedProps) => {
  const [formLoading, setLoading] = useState(false);
  const [goodRecieved, setGoodRecieved] = useState(goodsRecievedDefaultData);
  const [items, setItems] = useState<any>([]);
  const [selectedPo, setSelectedPo] = useState<any>([]);
  let totalAmount = 0;
  const { loading, getPurchaseOrder, purchaseOrder, getGoodsRecieved } =
    useRequisition();
  const { getCashAccount, cashAccounts, getExpenseAccount, expenseAccounts } =
    useAccounts();
  const { formattedUsers } = useUsers();
  const { suppliers } = useSuppliers();

  const validator = new Validator();

  useEffect(() => {
    getPurchaseOrder("Pending");
    getCashAccount();
    getExpenseAccount();
  }, []);

  const handleSelected = (e: any) => {
    const { value, name } = e;
    console.log('PO-data', purchaseOrder[value-1])
    if (value !== "") {
      if (name === "purchase_order_id") {
        setItems(
          purchaseOrder.find((item: any) => item?.id === value)?.items || []
        );
        setSelectedPo(purchaseOrder.find((item: any) => item?.id === value));
      }
      setGoodRecieved({
        ...goodRecieved,
        [name]: { ...goodRecieved[name], error: false, data: value },
      });
    } else {
      setGoodRecieved({
        ...goodRecieved,
        [name]: { ...goodRecieved[name], error: true, data: value },
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setGoodRecieved({
      ...goodRecieved,
      [name]: { ...goodRecieved[name], error: false, data: value },
    });
  };

  

  // const handleReceivedQuantityChange = (index: number, value: string) => {
  //   const updatedItems = [...items]; // Make a shallow copy of items array
  //   updatedItems[index] = {
  //     ...updatedItems[index], // Make a shallow copy of the item object
  //     received: value, // Update the received_quantity property
  //   };
  //   console.log([index, value, updatedItems[index]["received"]]);
  //   setItems(updatedItems); // Update the state with the modified copy
  // };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    // eslint-disable-next-line array-callback-return
    items?.map((item: any) => {
      totalAmount += (parseFloat(item.received ) || 0) * parseFloat(item?.price);
    });

    if (validator.checkForEmptyFields(goodRecieved, setGoodRecieved)) {
      setLoading(true);
      const data = {
        ...getFormData(goodRecieved),
        ...{ total_amount: totalAmount },
        ...{items: items},
        ...{reference_no:selectedPo?.reference_no},
        ...{supplier_id:selectedPo?.supplier_id}
      };

      doCreate("grns", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "New received goods have been successfully submitted",
              successToastOptions
            );
            getGoodsRecieved();
            refresh();
            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch((err) => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatPoSelectData(purchaseOrder,suppliers)}
            id="purchase_order_id"
            label="Purchase Order"
            name="purchase_order_id"
            required
            handleChange={handleSelected}
            error={goodRecieved.purchase_order_id.error}
            errorMessage="Please select the purchase order received"
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            label="Delivery Date"
            name="delivery_date"
            required
            noLimit={true}
            error={goodRecieved.delivery_date.error}
            errorMessage="Please select the date"
            handleChange={handleSelected}
          />
        </div>
        {/* <div className="col-sm-4">
          <InputSelect
            data={formatSupplierSelectData(suppliers)}
            id="supplier_id"
            name="supplier_id"
            label="Supplier"
            handleChange={handleSelected}
            required
            errorMessage="Please select supplier"
            value={goodRecieved.supplier_id.data}
          />
        </div> */}
      </div>

      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity</th>
              {/* <th>Received Quantity</th> */}
              <th>Price/Unit</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                {/* <td>
                  <InputNoLabel
                    type="number"
                    name={`received_${index}`}
                    label="Received"
                    value={(item.received)}
                    onChange={(e: any) =>
                      handleReceivedQuantityChange(index, e.target.value)
                    }
                  />
                </td> */}
                <td>{item.rate}</td>
                <td>
                  {(parseFloat(item.received <= 0 ? item.quantity : item.received)) * item.rate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formattedUsers}
            id="received_by"
            label="Received by"
            name="received_by"
            required
            error={goodRecieved.received_by.error}
            errorMessage="Please select the person who received the goods"
            handleChange={handleSelected}
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddGoodsRecieved;
