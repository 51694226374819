interface Item {
  [key: string]: any;
}

interface Filters {
  [key: string]: any;
}

const filterArray = (items: Item[], filters: Filters): Item[] => {
  return items.filter((item) => {
    return Object.keys(filters).every((key) => {
      if (filters[key] === undefined || filters[key] === null) {
        return true; // Ignore undefined or null filters
      }
      if (typeof filters[key] === "string") {
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(filters[key].toString().toLowerCase());
      }
      if (typeof filters[key] === "number") {
        return item[key] === filters[key];
      }
      if (typeof filters[key] === "boolean") {
        return item[key] === filters[key];
      }
      return true; // Add more conditions as needed
    });
  });
};

export { filterArray };
