import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { RootState } from '../redux/app/store';
import { setCounties, setCountries, setDistricts, setParishes, setSubCounties, setVillages } from '../redux/reducer/geoData';

const useGeoData = () => {
  const dispatch = useDispatch();
  const {
    districts, villages, subCounties, counties, parishes, countries, _districts, _parishes, _subCounties, _counties, _villages
  } = useSelector(
    (state: RootState) => state.geoData,
  );
  const [loading, setLoading] = useState(false);
  
  const refreshData = async () => {
    try {
      setLoading(true);
      const allVillages = await doGet('villages');
      const allSubCounties = await doGet('subcounties');
      const allCounties = await doGet('counties');
      const allParishes = await doGet('parishes');
      const allDistricts = await doGet('districts');
      const allCountries = await doGet('location/countries');

      dispatch(setVillages(allVillages?.data?.data));
      dispatch(setSubCounties(allSubCounties?.data?.data));
      dispatch(setCounties(allCounties?.data?.data));
      dispatch(setParishes(allParishes?.data?.data));
      dispatch(setDistricts(allDistricts?.data?.data));
      dispatch(setCountries(allCountries?.data?.data));
    } catch (e) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getAll = async () => {
      if (districts.length === 0) {
        await refreshData();
      }
    };
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { villages, subCounties, counties, parishes, countries, districts, loading, _districts, _parishes, _subCounties, _counties,_villages, refreshData };
}

export default useGeoData;
