import React from "react";
import DashboardWrapper from "../../../components/DashboardWrapper";
import Breadcrumbs from "../../../parts/Breadcrumbs";
import ImplementingPartnersDashboard from "../../../components/project/implementingFunder/Funders";

const ImplementingPartners = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Implementing Partners" />
        <ImplementingPartnersDashboard />
      </>
    </DashboardWrapper>
  );
};

export default ImplementingPartners;
