import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useStaff from '../../../hooks/useStaff';
import ConfirmDelete from '../../global/ConfirmDelete';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';
import MyModal from '../../global/MyModal';
import { fetchAllEmployees } from '../../../api/hr';

const EmployeesTable = () => {
  const { employees, loading, refresh } = useStaff();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  useEffect(()=> {
    async function getEmployeesList () {
      const emps = await fetchAllEmployees()
    }

    getEmployeesList()
    
  }, [])
  
  const applicationColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Employee Names",
      selector: (row) => `${row.user.first_name} ${row.user.last_name}`,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 2,
      name: "Job title",
      selector: (row) => row.job_title,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Salary",
      selector: (row) => row.salary,
      reorder: true,
      sortable: true
    }, {
      id: 4,
      name: "Duty Station",
      selector: (row) => row.duty_station,
      reorder: true,
      sortable: true
    }, {
      id: 5,
      name: "Origin",
      selector: (row) => row.place_of_birth,
      reorder: true,
      sortable: true,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/hr/employees/view/${row.id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.preventDefault();
            setSelectedId(row.id);
            setIsOpen(true);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id="add-new-activity" title="Add new Activity">
        <ConfirmDelete closeModal={handleModalClick} path={`staff/${selectedId}/delete`} refresh={refresh} />
      </MyModal>
      <div className="table-responsive mt-15">

        {loading ? (<EllipsisLoader />) : employees.length > 0 && (
          <MyDataTable data={employees} columns={applicationColumnData} title="" />
        )}
      </div>
    </>
  )
}

export default EmployeesTable