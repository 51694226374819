import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  prchaseOrderDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useCommittees from "../../../hooks/useCommittees";
import useRequisition from "../../../hooks/useRequisition";
import {
  formatbidAnanlysisSelectData,
  formatbidSelectData,
  formatBidsSelectData,
  formatStaffSelectData,
  formatSupplierSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import Textarea from "../../global/Textarea";
import Input from "../../global/Input";
import useSuppliers from "../../../hooks/useSuppliers";
import DynamicForm from "../../global/DynamicForm";
import useBids from "../../../hooks/useBids";
import { filterArray } from "../../../utils/filterarray";


interface IAddPurchaseOrderProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddPurchaseOrder = ({ closeModal, refresh }: IAddPurchaseOrderProps) => {
  const [formLoading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(prchaseOrderDefaultData);
  const [items, setItems] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // Updated to string array
  const [selectedPr, setSelectedPr] = useState<any>([]);
  const { loading, getBidAnalysis, bidAnalysis, requisitions } =
    useRequisition("Approved");
  const { approvingCommitte, getApprovingCommitteeMembers } = useCommittees();
  const { suppliers } = useSuppliers();
  const defaultData = [{ item_id: "", rate: "", quantity: "", total: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);
  const {bids} = useBids()

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getApprovingCommitteeMembers(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBidAnalysis(), []);

  const handleSelected = (e: any) => {
    const { value, name } = e;
    if (value !== "") {
      if (name === "purchase_requisition_id") {
        setItems(
          requisitions.find((item: any) => item?.id === value)?.items || []
        );
        setSelectedPr(requisitions.find((item: any) => item?.id === value));
      }
      // update the state
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    if (value !== "") {
      // update the state
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setPurchaseOrder({
        ...purchaseOrder,
        ...{
          [name]: { ...purchaseOrder[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(purchaseOrder, setPurchaseOrder)) {
      setLoading(true);
      const dataValid = getFormData(purchaseOrder);
      const selectedItemsData = items.filter((item: any) =>
        selectedItems.includes(item.id)
      ); // Filter items based on selectedItems array
      const data = {
        purchase_requisition_id: dataValid.purchase_requisition_id,
        supplier_id: dataValid.supplier_id,
        authorised_by: dataValid.authorised_by,
        delivery_date: dataValid.delivery_date,
        items: selectedItemsData,
        status: "Pending",
        comment: dataValid.comment,
        reference_no: selectedPr?.purchase_requisition_no,
      };

      doCreate("purchase_orders", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "New Purchase Order has been successfully submitted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleItemSelect = (itemId: string) => {
    const isSelected = selectedItems.includes(itemId);
    let newSelectedItems;

    if (isSelected) {
      newSelectedItems = selectedItems.filter((id: string) => id !== itemId);
    } else {
      newSelectedItems = [...selectedItems, itemId];
    }

    setSelectedItems(newSelectedItems);
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={formatBidsSelectData(bids)}
            id="supplier_id"
            name="supplier_id"
            //data={formatRequisitionSelectData(requisitions)}
            label="Purchase Requisition"
            handleChange={handleSelected}
            required
            //value={bid.requisition_id.data}
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatbidAnanlysisSelectData(
              filterArray(bidAnalysis, {
                requisition_id: purchaseOrder?.requisition_id?.data,
                // status_id: 2,
              })
            )}
            id="bid_analysis_id"
            name="bid_analysis_id"
            label="Selected Bid"
            handleChange={handleSelected}
            errorMessage="Please select bid"
            value={purchaseOrder?.bid_analysis_id?.data}
            required
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatSupplierSelectData(suppliers)}
            id="supplier_id"
            name="supplier_id"
            label="Supplier"
            handleChange={handleSelected}
            required
            errorMessage="Please select supplier"
            value={purchaseOrder?.supplier_id?.data}
          />
        </div>
        <div className="col-sm-4">
          <Input
            name="reference_no"
            label="Ref No"
            handleChange={handleChange}
            required
            errorMessage="Please select reference number"
            value={selectedPr?.purchase_requisition_no}
          />
        </div>
      </div>

      {/* Dropdown select for items */}
      {requisitions.find(
        (req: { id: string }) => req.id == purchaseOrder?.requisition_id?.data
      )?.items?.length > 0 && (
        <div className="form-row">
          <div className="col-sm-12">
            <InputSelect
              data={items.map((item: any) => ({
                value: item.id,
                label: item.item.name,
              }))}
              id="item_id"
              label="Select Item (s)"
              name="item_id"
              required
              handleChange={(e: any) => handleItemSelect(e.value)}
              errorMessage="Please select an item"
            />
          </div>
        </div>
      )}

      {/* Table displaying Requested items */}
      {requisitions.find(
        (req: { id: string }) => req.id == purchaseOrder?.requisition_id?.data
      )?.items?.length > 0 && (
        <div className="table-responsive">
          <div className="fs-3 fw-bolder ">Requested Items</div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price/Unit</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {requisitions
                .find(
                  (req: { id: string }) =>
                    req.id == purchaseOrder?.requisition_id?.data
                )
                ?.items?.map((item: any, index: number) => (
                  <tr key={index}>
                    {
                      <>
                        <td>{item?.item?.name}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.rate}</td>
                        <td>
                          {Number(item?.quantity * item?.rate).toLocaleString()}
                        </td>
                      </>
                    }
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Table displaying Bid items */}
      {/* @ts-ignore */}
      {bidAnalysis?.find(
        (bid) => bid.id.toString() == purchaseOrder?.bid_analysis_id?.data
      )?.items?.length > 0 && (
        <div className="table-responsive">
          <div className="fs-3 fw-bolder ">Bid Items</div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price/Unit</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {bidAnalysis
                ?.find(
                  (bid) =>
                    bid.id.toString() == purchaseOrder?.bid_analysis_id?.data
                )
                ?.items?.map((item: any, index: number) => (
                  <tr key={index}>
                    {
                      <>
                        <td>{item?.item?.name}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.rate}</td>
                        <td>
                          {Number(item?.quantity * item?.rate).toLocaleString()}
                        </td>
                      </>
                    }
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            label="Comment"
            handleChange={handleChange}
            name="comment"
            error={purchaseOrder.comment.error}
            errorMessage="Please enter some comment about this action"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatStaffSelectData(approvingCommitte)}
            id="authorised_by"
            label="Authorised by"
            name="authorised_by"
            required
            errorMessage="Please the personal who authorised the action"
            error={purchaseOrder.authorised_by.error}
            handleChange={handleSelected}
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            label="Delivery Date"
            name="delivery_date"
            required
            noLimit={true}
            error={purchaseOrder.delivery_date.error}
            errorMessage="Please select date"
            value={purchaseOrder.delivery_date.data}
            handleChange={handleSelected}
          />
        </div>
      </div>
      {/* <div className="form-row">
        <DynamicForm
          defaultData={defaultData}
          setValues={setValues}
          values={values}
        />
      </div> */}
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddPurchaseOrder;
