import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { createEmployee } from '../../../../api/hr';
import { employeeFinalDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import useDepartments from '../../../../hooks/useDepartments';
import useEmployees from '../../../../hooks/useEmployees'
import useStaff from '../../../../hooks/useStaff';
import useUsers from '../../../../hooks/useUsers';
import { ROUTES } from '../../../../routes/routes';
import Validator from '../../../../utils/Validator';
import Input from '../../../global/Input'
import InputSelect from '../../../global/InputSelect';

const Finish = () => {
  const { prev, addEmployeeEmploymentDetails, addEmployeeEducationDetails, addEmployeeOtherTrainingDetails, addEmployeeRefereeDetails, employeeAddressDetails, employeePersonalDetails, reset } = useEmployees();
  const { formattedDepartments } = useDepartments();
  const { refresh } = useStaff();
  const { formattedUsers } = useUsers();
  const [finalDetails, setFinalDetails] = useState(employeeFinalDefaultData);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();

  const router = useHistory();
  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box

    if (value !== '') {
      // update the state
      setFinalDetails({ ...finalDetails, ...{ [name]: { ...finalDetails[name], error: false, data: value } } });
    } else {
      // indicate an error
      setFinalDetails({ ...finalDetails, ...{ [name]: { ...finalDetails[name], error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    if (value !== '') {
      // update the state
      setFinalDetails({ ...finalDetails, ...{ [name]: { ...finalDetails[name], error: false, data: value } } });
    } else {
      // indicate an error
      setFinalDetails({ ...finalDetails, ...{ [name]: { ...finalDetails[name], error: true, data: value } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const localStorageData = localStorage.getItem('newUser');

    if(localStorageData) {
      const newUser = JSON.parse(localStorageData)

      if (validator.checkForEmptyFields(finalDetails, setFinalDetails)) {
        setLoading(true);
        const finaldata = {
          department_id: finalDetails.department_id.data,
          job_title: finalDetails.job_title.data,
          //duty_station: finalDetails.duty_station.data,
          salary: finalDetails.salary.data,
          //work_id: finalDetails.work_id.data,
          ...newUser
        }
  
        const data = {
          address: employeeAddressDetails,
          education: addEmployeeEducationDetails,
          employment: addEmployeeEmploymentDetails,
          other_training: addEmployeeOtherTrainingDetails,
          referees: addEmployeeRefereeDetails,
          nok: [],
          children: [],
          no_of_children: 1,
          ...employeePersonalDetails,
          ...finaldata
        }
        createEmployee(data).then((response) => {
          if (response.data.success) {
            toast.success('New employee registered successfully', successToastOptions);
            reset();
            refresh();
            setTimeout(() => {
              router.push(ROUTES.EMPLOYEES);
            }, 1000);
            localStorage.removeItem("newUser")
          } else {
            toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
            localStorage.removeItem("newUser")
          }
        }).catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        }).finally(() => {
          setLoading(false);
        });
      }
    }
  }
    


  return (
    <div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formattedDepartments} label="Department" name='department_id' id='department_id' value={finalDetails.department_id.data} error={finalDetails.department_id.error} errorMessage="Please select the department" required handleChange={handleSelectChange} />
          {/* <Input label='Department' name='department_id' handleChange={handleChange} /> */}
        </div>
        <div className="col-sm-6">
          <Input label='Duty station' name='duty_station' handleChange={handleChange} value={finalDetails.duty_station.data} error={finalDetails.duty_station.error} errorMessage="Please provide the duty_station" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Job Title' name='job_title' handleChange={handleChange} value={finalDetails.job_title.data} error={finalDetails.job_title.error} errorMessage="Please provide the job title" required />
        </div>
        <div className="col-sm-6">
          <Input label='Work id' name='work_id' handleChange={handleChange} value={finalDetails.work_id.data} error={finalDetails.work_id.error} errorMessage="Please provide the work id" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Salary' type='number' name='salary' handleChange={handleChange} value={finalDetails.salary.data} error={finalDetails.salary.error} errorMessage="Please provide the estimated salary" required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='button' className='btn btn-outline-danger mr-3' onClick={prev}>Prev</button>
        <button type='button' className='btn  btn-info' disabled={loading} onClick={handleSubmit}>{loading ? 'Submiting...' : 'Submit'}</button>
      </div>
    </div>
  )
}

export default Finish