import { createSlice } from '@reduxjs/toolkit';

export interface IBidStages {
    bidStage: any;
    bidStages: any;
    selectedBid: any;
    bids: any;
}

const initialState: IBidStages = {
  bidStage: {},
  bidStages: [],
  selectedBid: {},
  bids: [],
};

export const bidStagesReducer = createSlice({
  name: 'bidStages',
  initialState,
  reducers: {
    setSelectedBidStage: (state, { payload }) => {
      state.bidStage = payload;
    },
    setBidStages: (state, { payload }) => {
      state.bidStages = payload;
    },
    setBids: (state, { payload }) => {
      state.bids = payload;
    },
    setSelectedBid: (state, { payload }) => {
      state.selectedBid = payload;
    },
  },
});

const { reducer, actions } = bidStagesReducer;

export const {
    setSelectedBidStage, setBidStages, setBids, setSelectedBid
} = actions;

export default reducer;