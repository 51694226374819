import React, { useEffect, useState } from 'react'
import { OtherImportantInfoData } from '../../../../constants'
import useEmployees from '../../../../hooks/useEmployees'
import Validator from '../../../../utils/Validator'
import Input from '../../../global/Input'
import InputSelect from '../../../global/InputSelect'
import RadioButton from '../../../global/RadioButton'
import Textarea from '../../../global/Textarea'
import { updateLocalStorage } from './dataPersistenceHelper'
import InputDate from '../../../global/InputDate'

const OtherImportantInfo = () => {
  const [personalDetails, setPersonalDetails] = useState(OtherImportantInfoData);
  const [convicted, setConvicated] = useState("No");
  const [disabled, setDisabled] = useState("No");
  const [discharged, setDischarged] = useState("No");
  const [hospitalized, setHospitalized] = useState("No");
  const validator = new Validator();
  const { storeEmployeePersonalDetails, prev, next } = useEmployees();

  useEffect(()=>{
    const userData: string | null = localStorage.getItem('newUser');
    const data = JSON.parse(userData ? userData : '')
    if(data.bank){
      personalDetails.bank.data = data.bank
      personalDetails.account_no.data = data.account_no
      personalDetails.spouse_name.data = data.spouse_name
      personalDetails.convicted.data = data.convicted
      personalDetails.disabled.data = data.disabled
      personalDetails.discharged_from_duty.data = data.discharged_from_duty
      personalDetails.hospitalized.data = data.hospitalized
      personalDetails.discharge_reason.data = data.discharge_reason || ' '
      personalDetails.disability_details.data = data.disability_details || ' '
      personalDetails.conviction_details.data = data.conviction_details || ' '
      personalDetails.hospitalized_details.data = data.hospitalized_details || ' '
    }
  },[])

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box

    if (value !== '') {
      // update the state
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: true, data: value } } });
    }
  };

  const handleRadioButtonChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === 'convicted') {
      setConvicated(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'conviction_details': { ...personalDetails['conviction_details'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'conviction_details': { ...personalDetails['conviction_details'], required: false } } });
      }

    }
    if (name === 'disabled') {
      setDisabled(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'disability_details': { ...personalDetails['disability_details'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'disability_details': { ...personalDetails['disability_details'], required: false } } });
      }
    }
    if (name === 'discharged_from_duty') {
      setDischarged(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'discharge_reason': { ...personalDetails['discharge_reason'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'discharge_reason': { ...personalDetails['discharge_reason'], required: false } } });
      }
    }
    if (name === 'hospitalized') {
      setHospitalized(value);
      if (value === 'Yes') {
        setPersonalDetails({ ...personalDetails, ...{ 'hospitalized_details': { ...personalDetails['hospitalized_details'], required: true } } });
      } else {
        setPersonalDetails({ ...personalDetails, ...{ 'hospitalized_details': { ...personalDetails['hospitalized_details'], required: false } } });
      }
    }

  }

  const handleSelectChange = (e: any) => {
    const { name, value } = e
   if (value !== '') {
      // update the state
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: false, data: value } } });
    } else  {
      // indicate an error
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: true, data: value } } });
    }
  }

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validator.checkForEmptyFields(personalDetails, setPersonalDetails)) {
      // continue
      const data = {
        bank: personalDetails.bank.data,
        account_no: personalDetails.account_no.data,
        spouse_name: personalDetails.spouse_name.data,
        convicted: convicted,
        disabled: disabled,
        discharged_from_duty: discharged,
        hospitalized: hospitalized,
        hospitalized_details: personalDetails.hospitalized_details.data,
        conviction_details: personalDetails.conviction_details.data,
        disability_details: personalDetails.disability_details.data,
        discharge_reason: personalDetails.discharge_reason.data,
        date_of_appointment: personalDetails.date_of_appointment.data,
        end_of_contract: personalDetails.end_of_contract.data
      }
      updateLocalStorage(data)
      storeEmployeePersonalDetails(data)
      next();
    }
  }

return (
    <fieldset>
        <div className="form-row">
          <div className="col-sm-6">
            <Input label='Bank Name' name='bank' handleChange={handleChange} value={personalDetails.bank.data} error={personalDetails.bank.error} errorMessage="Please provide the bank name" required />
          </div>
          <div className="col-sm-6">
            <Input label='Account Number' name='account_no' handleChange={handleChange} value={personalDetails.account_no.data} error={personalDetails.account_no.error} errorMessage="Please provide the account number" required />
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            <Input label='Spouse name' name='spouse_name' handleChange={handleChange} value={personalDetails.spouse_name.data} error={personalDetails.spouse_name.error} errorMessage="Please provide the spouse name" />
          </div>
        </div>
        <div className='form-row'>
          <div className="col-sm-6">
            <InputDate
              name="date_of_appointment"
              handleChange={handleSelectChange}
              label="Date of Appointment"
              id="date_of_appointment"
              value={personalDetails.date_of_appointment.data}
              error={personalDetails.date_of_appointment.error}
              errorMessage="Please provide the new staff appointment date."
              required
              maxData={18}
            />
          </div>
          <div className="col-sm-6">
            <InputDate
              name="end_of_contract"
              handleChange={handleSelectChange}
              label="End Of Contract"
              id="end_of_contract"
              value={personalDetails.end_of_contract.data}
              error={personalDetails.end_of_contract.error}
              errorMessage="Please provide the end of contract date."
              required
              maxData={18}
            />
          </div>
        </div>
        <div className="form-row">
        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Disabled?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='disabled' label='Yes' checked={disabled === 'Yes'} value={'Yes'} id="disabled_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='disabled' label='No' checked={disabled === 'No'} value={'No'} id="disabled_no" />
            </div>
          </div>
          <div className="col">
            {disabled === "Yes" &&
              <Textarea name='disability_details' label='Disability details' handleChange={handleChange} required={disabled === 'Yes'} error={personalDetails.disability_details.error} value={personalDetails.disability_details.data} errorMessage="Please provide the details about the disability" />
            }
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Hospitalized before?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='hospitalized' label='Yes' checked={hospitalized === 'Yes'} value={'Yes'} id="hospitalized_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='hospitalized' label='No' checked={hospitalized === 'No'} value={'No'} id="hospitalized_no" />
            </div>
          </div>
          <div className="col">
            { hospitalized === "Yes" && 
             <Textarea name='hospitalized_details' label='Hosptilalized details' handleChange={handleChange} required={hospitalized === 'Yes'} error={personalDetails.hospitalized_details.error} value={personalDetails.hospitalized_details.data} errorMessage="Please provide the hospitalized details" />
            }
          </div>

        </div>
      </div>
      <div className="form-row">
          <div className="col-sm-6">
              <label>Ever discharged from duty?</label>
              <div>
                <RadioButton handleChange={handleRadioButtonChange} name='discharged_from_duty' label='Yes' checked={discharged === 'Yes'} value={'Yes'} id="discharged_from_duty_yes" />
                <RadioButton handleChange={handleRadioButtonChange} name='discharged_from_duty' label='No' checked={discharged === 'No'} value={'No'} id="discharged_from_duty_no" />
              </div>
            <div className="col">
              {discharged === "Yes" && 
                <Textarea name='discharge_reason' label='Discharge reason' handleChange={handleChange} required={discharged === 'Yes'} error={personalDetails.discharge_reason.error} value={personalDetails.discharge_reason.data} errorMessage="Please provide the reason for duty discharge" />
              }
            </div>
          </div>
          <div className="col-sm-6">
              <label>Convicted?</label>
              <div>
                <RadioButton handleChange={handleRadioButtonChange} name='convicted' label='Yes' checked={convicted === 'Yes'} value={'Yes'} id="convicted_yes" />
                <RadioButton handleChange={handleRadioButtonChange} name='convicted' label='No' checked={convicted === 'No'} value={'No'} id="convicted_no" />
              </div>
            <div className="col">
              {convicted === "Yes" &&
                <Textarea name='conviction_details' label='Conviction details' handleChange={handleChange} required={convicted === 'Yes'} error={personalDetails.conviction_details.error} value={personalDetails.conviction_details.data} errorMessage="Please provide the conviction details" />
              }
            </div>
          </div>
      </div>
          <div className="form-row m-0 pb-4 pt-3 justify-content-end">
           <button type='button' className='btn btn-outline-danger mr-3' onClick={prev}>Prev</button>
           <button type='button' className='btn  btn-info' onClick={handleNext}>Next</button>         
          </div> 
    </fieldset>
  )
}
export default OtherImportantInfo