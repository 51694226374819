import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  cashRequisitionDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useCashRequisition from "../../../hooks/useCashRequisition";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import Textarea from "../../global/Textarea";
import DynamicCashRequisitionForm from "../../global/DynamicCashRequisitionForm";
interface IAddRequisitionProps {
  onCancel: () => void;
}
const AddProposal = ({ onCancel }: IAddRequisitionProps) => {
  const [loading, setLoading] = useState(false);
  const [requisition, setRequisition] = useState(cashRequisitionDefaultData);
  const defaultData = [{ name: "", amount: "0" }];
  const [values, setValues] = useState<Array<any>>(defaultData);
  const [total, setTotal] = useState(0)

  const validator = new Validator();
  const { refresh } = useCashRequisition();

  useEffect(() => {
    let totalAmount: number = 0;
    if (values.length > 0) {
        values.forEach((value) => {
          totalAmount += parseInt(value.amount);
        });
        setTotal(totalAmount);
        requisition.amount.data = totalAmount.toString();
    }
  }, [values]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(requisition, setRequisition)) {
      setLoading(true);
      const data = {
        amount: requisition.amount.data,
        description: requisition.description.data,
        request_date: requisition.request_date.data,
        delivery_date: requisition.delivery_date.data,
        reason: requisition.amount.data,
        items: values,
      };
      console.log('dt', data)

      doCreate("cash-requisitions/create", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your requisition has been successfully submitted",
              successToastOptions
            );
            refresh();
            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputDate
            name="issue_date"
            label="Issue Date"
            handleChange={handleSelectChange}
            required
            value={requisition.request_date.data}
            error={requisition.request_date.error}
            errorMessage="Please select the issue date"
          />
        </div>
        <div className="col-sm-6">
          <InputDate
            name="expiry_date"
            label="Expiry Date"
            handleChange={handleSelectChange}
            required
            noLimit
            value={requisition.delivery_date.data}
            error={requisition.delivery_date.error}
            errorMessage="Please select the expiry date"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            label="Subject"
            name="subject"
            id="subject"
            handleChange={handleChange}
            required
            value={requisition.reason.data}
            error={requisition.reason.error}
            errorMessage="Please enter the subject"
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Total Amount"
            name="amount"
            id="amount"
            style={{color: 'white'}}
            handleChange={handleChange}
            required
            type="number"
            value={requisition.amount.data}
            error={requisition.amount.error}
            errorMessage="Please provide the amount"
            readonly
          />
        </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <Textarea
              handleChange={handleChange}
              name="description"
              label="Description"
              //value={quotation.description.data}
              //error={quotation.description.error}
              errorMessage="Please enter the description"
              required
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-row">
            <DynamicCashRequisitionForm
              defaultData={defaultData}
              setValues={setValues}
              values={values}
            />
          </div>
        </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddProposal
