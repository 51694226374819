import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import ListBids from '../../components/procurement/bids/ListBid'

const Bids = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Bids' />
        {/* body */}
        <ListBids />
      </>
    </DashboardWrapper>
  )
}

export default Bids