import React, { useEffect, useState } from "react";
import useBidStages from "../../../../hooks/useBidStages";
import Input from "../../../global/Input";
import toast from "react-hot-toast";
import { doCreate } from "../../../../api";
import { successToastOptions, errorToastOptions, ERRORMESSAGE } from "../../../../constants";
import { formatErrorMessage } from "../../../../helpers";
import Validator from "../../../../utils/Validator";
import InputSelect from "../../../global/InputSelect";
import { formatBidStageSelectData } from "../../../../utils/helpers";

interface BidStageProps {
  id: any;
  onCancel: ()=> void
}

const ReOrderBidStage = ({ id, onCancel }: BidStageProps) => {
  const { getBidStage, loading, bidStage, refresh, bidStages } = useBidStages();

  useEffect(()=> refresh(), [])
  useEffect(()=> console.log('bds', bidStages), [bidStages])

  useEffect(() => getBidStage(id), []);

  useEffect(() => {
    console.log('zaq', bidStage)
  }, [bidStage]);



//   const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
//     e.preventDefault();
//         doCreate("bid_stages", data)
//           .then((response) => {
//             if (response.data.success === true) {
//               toast.success(
//                 "Your bid stage has been successfully submitted",
//                 successToastOptions
//               );
//               refresh();

//               setTimeout(() => {
//                 onCancel();
//               }, 1000);
//             } else {
//               toast.error(
//                 formatErrorMessage(response.data.data, response.data.message),
//                 errorToastOptions
//               );
//             }
//           })
//           .catch(() => {
//             toast.error(ERRORMESSAGE, errorToastOptions);
//           })
//           .finally(() => {
//             //setLoading(false);
//           });
//   };

  return (
    <form>
        {bidStages.length > 0 &&
            bidStages.map((stage: {name: string, order_no: number}, index: number) => (
                <div className="form-row" key={index}>  
                    <div className="col-sm-4">
                        <Input
                            type="text"
                            id="bid_stage"
                            name="name"
                            label="Bid Stage"
                            handleChange={()=> console.log(0)}
                            value={stage.name}
                            // error={bidStage.name.error}
                            errorMessage="Please enter the new bid stage name"
                            readonly = {true}
                            required
                        />
                    </div>
                    <div className="col-sm-4">
                        <InputSelect
                            id="order_no"
                            name="order_no"
                            label="Order No"
                            //handleChange={() => console.log(0)}
                            value={stage.order_no}
                            defaultValue={`${stage.order_no}`}
                            // error={bidStage.name.error}
                            //errorMessage="Please enter the new bid stage name"
                            data={formatBidStageSelectData(bidStages)}  
                            required                      
                        />
                    </div>
                
                </div>
            ))
        }
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          //onClick={handleSubmit}
        >
          {loading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </form>
  );
};

export default ReOrderBidStage
