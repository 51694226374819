import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { errorToastOptions } from "../../constants";
import Input from "./Input";
import { EllipsisLoader } from "./MyLoaders";
import useIncomeStatement from "../../hooks/useIncomeStatement";
import Select from "./Select";
import InputSelect from "./InputSelect";

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>;
}

interface Structure {
  id: number,
  cat_name: string,
  categories: [categoryStructure]
}
interface categoryStructure {
  sub_cat_name: string;
  sub_cat_code: string;
  ledgers: [ledger]
}

interface ledger {
  id: number;
  balance: {total: number} | null;
}

interface expense {
  categories: []
}

const DynamicCashRequisitionForm = ({
  defaultData,
  values,
  setValues,
}: IDynamicFormProps) => {
  const { incomeStatement, getIncomeStatement, loading} = useIncomeStatement();
  const [expense, setExpense] = useState<expense>()
  const [currentName, setCurrentName] = useState('')

  useEffect(() => getIncomeStatement(), []);

  useEffect(()=>{
    if(incomeStatement){
      const expenses = incomeStatement.filter((item: Structure) => item.cat_name === 'MATUMIZI/EXEPENSES');
      setExpense(expenses[0])
    }
  },[incomeStatement])

  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  };

  const handleMinusClick = (index: number) => {
    const newState = values;
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    }
    newState.splice(index, 1);
    setValues([...newState]);
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, id } = e.target as HTMLInputElement;
    console.log(id, e.target)
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  };


  const handleSelectChange = (index: any, e: any) => {
    const {name, value} = e;
    const newState = values;
    newState[Number(index)][name] = value;
    setValues(newState);
  };



  return (
    <div className="col-sm-12">
      <p>Please breakdown the items here</p>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={3}>Name</th>
            <th>Amount</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {values &&
            values.length > 0 &&
            values.map((item, index) => (
              <tr key={index}>
                <td colSpan={3}>
                {expense && 
                  <InputSelect
                    label=""
                    data={expense.categories.map((item: {description: string}) => ({ value: item.description, label: item.description }))} 
                    name={'name'} 
                    handleChange={(e)=>handleSelectChange(index, e)} 
                    id={index} 
                    required
                  />
                }
                </td>

                <td width={"30%"}>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`amount`}
                    type="number"
                    noLabel
                  />
                </td>
                <td>
                  <div className="form-row flex-nowrap">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => handleMinusClick(index)} // remove a friend from the list
                      >
                        -
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() => handleAddClick()} // insert an empty string at a position
                      >
                        +
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicCashRequisitionForm;
