import { AuthenticatedAPIInstance } from "../../utils/axios";

export const getAllEmployees = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`staff/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
  
  const allEmployees = await AuthenticatedAPIInstance.get("staff").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const fetchAllEmployees = async () => { 
  await AuthenticatedAPIInstance.get("staff")
  .then((response) =>   {console.log('res res', response.data) })
  .catch((error) => error);
}

export const createEmployee = async (data: any) => {
  const allEmployees = await AuthenticatedAPIInstance.post("staff/create", data).then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const getAllLeaves = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`leave/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }

  const allEmployees = await AuthenticatedAPIInstance.get("leave").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const createNewMonthlyActivity = async (data: any) => {
  const allEmployees = await AuthenticatedAPIInstance.post(`monthly-activities/create`, data).then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const getAllMonthlyActivities = async (id?: number) => {
  if (typeof id === 'number') {
    const allActivities = await AuthenticatedAPIInstance.get(`activity${id}`).then((response) => response)
      .catch((error) => error);
    return allActivities;
  }

  const allActivities = await AuthenticatedAPIInstance.get("monthly-activities").then((response) => response)
    .catch((error) => error);
  return allActivities;
}

export const createNewSchedule = async (data: any) => {
  const schedule = await AuthenticatedAPIInstance.post(`staff-annual-schedules/create`, data).then((response) => response)
    .catch((error) => error);
  return schedule;
}

export const getAllSchedules = async (id?: number) => {
  if (typeof id === 'number') {
    const allSchedules = await AuthenticatedAPIInstance.get(`staff-annual-schedules/${id}`).then((response) => response)
      .catch((error) => error);
    return allSchedules;
  }

  const allSchedules = await AuthenticatedAPIInstance.get("staff-annual-schedules").then((response) => response)
    .catch((error) => error);
  return allSchedules;
}

export const createNewTimeSheet = async (data: any) => {
  const timeSheet = await AuthenticatedAPIInstance.post(`timesheets/create`, data).then((response) => response)
    .catch((error) => error);
  return timeSheet;
}

export const getAllTimeSheets = async (id?: number) => {
  if (typeof id === 'number') {
    const allTimeSheets = await AuthenticatedAPIInstance.get(`timesheets/${id}`).then((response) => response)
      .catch((error) => error);
    return allTimeSheets;
  }

  const allTimeSheets = await AuthenticatedAPIInstance.get("timesheets").then((response) => response)
    .catch((error) => error);
  return allTimeSheets;
}

export const deleteSelectedMonthlyActivity = async (id?: number) => {
  if (typeof id === 'number') {
    await AuthenticatedAPIInstance.get(`monthly-activities/${id}/delete`).then((response) => response)
    .catch((error) => error);
  }
}

export const createLeave = async (data: any) => {
  const allEmployees = await AuthenticatedAPIInstance.post(`leave/create`, data).then((response) => response)
    .catch((error) => error);
  return allEmployees;
}


export const getAllPayrolls = async (id?: number) => {
  if (typeof id === 'number') {
    const allPayrolls = await AuthenticatedAPIInstance.get(`payrolls/${id}`).then((response) => response)
      .catch((error) => error);
    return allPayrolls;
  }
  const allPayrolls = await AuthenticatedAPIInstance.get("payrolls").then((response) => response)
    .catch((error) => error);
  return allPayrolls;
}

export const createPayroll = async (data: any) => {
  const newPayroll = await AuthenticatedAPIInstance.post(`payrolls/create`, data).then((response) => response)
    .catch((error) => error);
  return newPayroll;
}

export const getStaffPayroll = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`payrolls/staff/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
}


export const getPayrollStats = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`payrolls/staff/${id}/stats`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
}

export const getAllPerformances = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`performance-cycles/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
  const allEmployees = await AuthenticatedAPIInstance.get("performance-cycles").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}