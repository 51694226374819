import React, { useEffect, useState } from "react";
import DynamicForm from "../../global/DynamicForm";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import Textarea from "../../global/Textarea";
import {
  bidDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import useCustomers from "../../../hooks/useCustomers";
import {
  formatRequisitionSelectData,
  formatSelectData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import { formatErrorMessage, isValidDeliveryDate } from "../../../helpers";
import { doCreate } from "../../../api";
import toast from "react-hot-toast";
import useSuppliers from "../../../hooks/useSuppliers";
import useRequisition from "../../../hooks/useRequisition";
import useBids from "../../../hooks/useBids";
import useBidStages from "../../../hooks/useBidStages";
import { EllipsisLoader } from "../../global/MyLoaders";
import { Requisition } from "../../../types/Requisition";

interface IAddBidProps {
  onCancel: () => void;
}

interface item {
  label: string;
  value: number | string;
}

interface Bid {
  requisition_id: string;
  date: string;
  bid_stage_id: string;
  supplier_id: string;
  status_id: string;
  description: string;
  items: BidItem[];
}
interface BidItem {
  item_id: string;
  rate: string;
  quantity: string;
  total: string;
}

const AddBid = ({ onCancel }: IAddBidProps) => {
  const [bid, setBid] = useState(bidDefaultData);
  const defaultData = [{ item_id: "", rate: "", quantity: "", total: "" }];
  const [bidItems, setBidItems] = useState<Array<BidItem>>(defaultData);
  const [selectedRequisition, setSelectedRequisition] = useState<Requisition>();

  const { loading, customers, refresh } = useCustomers();

  const {refresh: bidListRefresh} = useBids()
  const { requisitions } = useRequisition();

  const { suppliers } = useSuppliers();
  const { loading: bidStagesLoading, bidStages } = useBidStages();

  const validator = new Validator();

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handlePurchaseReqSelectChange = (e: any) => {
    // console.log("event", e);
    const selectedReq = requisitions.find(
      (req: { id: number }) => req.id === e.value
    );

    setSelectedRequisition(selectedReq);
    const updateBidItems = (selectedReq: Requisition) => {
      const updatedBidItems = selectedReq
        ? selectedReq.items.map((item) => ({
            name: item.item.name,
            item_id: item.id.toString(),
            quantity: item.quantity.toString(),
            rate: item.item.price.toString(),
            total: item.total.toString(),
          }))
        : [];

      setBidItems(updatedBidItems);
    };

    updateBidItems(selectedReq);
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(bid, setBid)) {
        const data: Bid = {
          requisition_id: bid.purchase_requisition.data,
          date: bid.issue_date.data,
          bid_stage_id: bid.bid_stage_id.data,
          supplier_id: bid.vendor_id.data,
          status_id: "1",
          description: bid.description.data,
          items: bidItems,
        };
        doCreate("bid-analysis/create", data)
          .then((response) => {
            if (response.data.success === true) {
              toast.success(
                "Your bid has been successfully submitted",
                successToastOptions
              );
              bidListRefresh()
              setTimeout(() => {
                onCancel();
              }, 1000);
            } else {
              toast.error(
                formatErrorMessage(response.data.data, response.data.message),
                errorToastOptions
              );
            }
          })
          .catch(() => {
            toast.error(ERRORMESSAGE, errorToastOptions);
          })
          .finally(() => {
            //setLoading(false);
        });
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <InputDate
            name="issue_date"
            label="Issue Date"
            handleChange={handleSelectChange}
            required
            //value={bid.issue_date.data}
            //error={bid.issue_date.error}
            errorMessage="Please select the issue date"
          />
        </div>

        <div className="col-sm-4">
          <InputSelect
            data={formatRequisitionSelectData(requisitions)}
            id="purchase_requisition"
            name="purchase_requisition"
            label="Purchase Requisition"
            handleChange={handlePurchaseReqSelectChange}
            required
            //value={bid.purchase_requisition.data}
          />
        </div>
        <div className="col-sm-4">
          {bidStagesLoading ? (
            <EllipsisLoader />
          ) : (
            <InputSelect
              data={formatSelectData(bidStages)}
              id="purchase_requisition"
              name="bid_stage_id"
              label="Bid Stage"
              handleChange={handleSelectChange}
              required
              //value={bid.vendor_id.data}
            />
          )}
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatSelectData(suppliers)}
            id="purchase_requisition"
            name="vendor_id"
            label="Vendor Name(s)"
            handleChange={handleSelectChange}
            //value={bid.vendor_id.data}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            handleChange={handleChange}
            name="description"
            label="Description"
            //value={bid.description.data}
            //error={bid.description.error}
            errorMessage="Please enter the bid description"
            required
          />
        </div>
      </div>

      <div className="form-row">
        {/* {selectedRequisition &&
         <bidDynamicForm
         defaultData={defaultData}
         setValues={setValues}
         values={values}
         requisitionItems={selectedRequisition?.items}
        />
       } */}

        {selectedRequisition && selectedRequisition?.items?.length > 0 && (
          <DynamicForm
            defaultData={defaultData}
            setValues={setBidItems}
            values={bidItems}
          />
        )}
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddBid;