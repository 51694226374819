import React from 'react'
import ListFunders from './ListFunders'
// import Stats from './Stats'

const ImplementingPartnersDashboard = () => {
  return (
    <>
      {/* <Stats /> */}
      <ListFunders />
    </>
  )
}

export default ImplementingPartnersDashboard