import React, { useEffect } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useEmployeeLeaves from '../../../hooks/useEmployeeLeaves';
import { formatState } from '../../../utils/helpers';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const LeaveTable = () => {
  const { leaves, loading } = useEmployeeLeaves();

  useEffect(()=> console.log('leaves' , leaves), [leaves]);

  const applicationColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Staff",
      selector: (row) => `${row.staff.user.first_name} ${row.staff.user.last_name}`,
      reorder: true,
      sortable: true
    },
    {
      id: 2,
      name: "Leave(days)",
      selector: (row) => row.days_applied_for,
      reorder: true,
      sortable: true
    }, {
      id: 3,
      name: "Leave type",
      selector: (row) => row.leave_type,
      reorder: true,
      sortable: true
    }, {
      id: 4,
      name: "Applied on",
      selector: (row) => row.date_of_application,
      reorder: true,
      sortable: true
    }, {
      id: 5,
      name: "Status",
      selector: (row) => <span className={`badge ${formatState(row.state_id).className}`}>{formatState(row.state_id).title}</span>,
      reorder: true,
      sortable: true
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/hr/leave-management/view/${row.id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1'><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return (
    <div className="table-responsive mt-15">

      {loading ? (<EllipsisLoader />) : (
        <MyDataTable data={leaves} columns={applicationColumnData} title="" />

      )}
    </div>
  )
}

export default LeaveTable