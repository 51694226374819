import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import ListActivities from "../../components/project/activities/ListActivities";
import Breadcrumbs from "../../parts/Breadcrumbs";
import { ROUTES } from "../../routes/routes";

const Activities = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs
          title="Activities"
          parents={[{ link: ROUTES.PROJECTS, title: "Projects" }]}
        />
        <ListActivities />
      </>
    </DashboardWrapper>
  );
};

export default Activities;
