import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  requisitionDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import Textarea from "../../global/Textarea";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import Input from "../../global/Input";
import DynamicForm from "../../global/DynamicForm";
import useRequisition from "../../../hooks/useRequisition";
import useDepartments from "../../../hooks/useDepartments";
import useUsers from "../../../hooks/useUsers";
import Validator from "../../../utils/Validator";
import QuotationDynamicForm from "../../global/QuotationDynamicForm";


interface IAcceptRequisitionProps {
  onCancel: () => void;
  id: number;
  refresh: () => void;
}

interface requisitionDefaultData {
  requisition_date: string,
  department_id: string,
  staff_id: string,
  items: item[],
  delivery_date: string,
  description: string,
  reference_no: string,
};

interface item {
  label: string;
  value: number | string
}


const ResubmitRequisition = ({
  onCancel,
  id,
  refresh,
}: IAcceptRequisitionProps) => {
  const [comment, setComment] = useState({ error: false, data: "" });
  const [loading, setLoading] = useState(false);
  const [selectedRequisition, setSelectedRequisition] = useState<requisitionDefaultData>()
  const [requisition, setRequisition] = useState(requisitionDefaultData);
  const { formattedDepartments } = useDepartments();
  const defaultData = [{ item_id: "", rate: "", quantity: "", total: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);
  const { formattedUsers } = useUsers();

  const { requisitions } = useRequisition("Declined");

  const validator = new Validator();

  useEffect(()=>{
    const selectedRequisition = requisitions.filter((req: {id: number})=>req.id === id)
    console.log('sl', selectedRequisition)
    requisition.requisition_date.data = selectedRequisition[0].requisition_date
    requisition.staff_id.data = selectedRequisition[0].staff_id
    requisition.items.data = selectedRequisition[0].items
    requisition.delivery_date.data = selectedRequisition[0].delivery_date
    requisition.description.data = selectedRequisition[0].description
    requisition.reference_no.data = selectedRequisition[0].reference_no
    console.log(requisition.requisition_date.data , requisition.department_id.data)
    setSelectedRequisition(selectedRequisition[0])
  },[requisitions])
  
  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target as HTMLInputElement;
    if (value !== "") {
      setComment({ error: false, data: value });
    } else {
      setComment({ error: true, data: "" });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  function isValidDeliveryDate(initialDate: string, deliveryDate: string): boolean {
    // Parse dates
    const initial = new Date(initialDate);
    const delivery = new Date(deliveryDate);

    return delivery >= initial;
}

const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
  e.preventDefault();
  if (validator.checkForEmptyFields(requisition, setRequisition)) {
    const isValid = isValidDeliveryDate(requisition.requisition_date.data, requisition.delivery_date.data)
    if(isValid){
      setLoading(true);
      const data = {
        department_id: requisition.department_id.data,
        description: requisition.description.data,
        requisition_date: requisition.requisition_date.data,
        delivery_date: requisition.delivery_date.data,
        items: requisition.items.data,
        requested_by: requisition.staff_id.data,
        reference_no: requisition.reference_no.data,
        status:  "pending",
      };

      doCreate("purchase_requisitions", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your requisition has been successfully submitted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }else{
      toast.error('The delivery date must be later than the requisition date.', errorToastOptions);
    }
  }
};

  return (
    <form>
        {selectedRequisition && 
          <>
            <div className="form-row">
        <div className="col-sm-4">
          <InputDate
            name="requisition_date"
            label="Request Date"
            handleChange={handleSelectChange}
            required
            value={requisition.requisition_date.data}
            error={requisition.requisition_date.error}
            errorMessage="Please select the request date"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedDepartments}
            id="department_id"
            name="department_id"
            label="Department"
            handleChange={handleSelectChange}
            required
            value={requisition.requisition_date.data}
            error={requisition.requisition_date.error}
            errorMessage="Please select the department to handle this requisition" />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedUsers}
            id="staff_id"
            name="staff_id"
            handleChange={handleSelectChange}
            value={requisition.staff_id.data} label={""}          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4">
          <Input
            name="reference_no"
            label="Ref No"
            handleChange={handleChange}
            value={requisition.reference_no.data}
            error={requisition.reference_no.error}
            errorMessage="Please enter the requisition reference number"
          />
        </div>

        <div className="col-sm-4">
          <InputDate
            name="delivery_date"
            label="Delivery Date"
            handleChange={handleSelectChange}
            required
            noLimit
            value={requisition.delivery_date.data}
            error={requisition.delivery_date.error}
            errorMessage="Please select the estimated delivery date"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            name="description"
            label="Review Description"
            handleChange={handleChange}
            required
            errorMessage="Please provide a comment"
            value={requisition.description.data} //changed
            error={comment.error}
          />
        </div>
      </div>
      <div className="form-row">
        {selectedRequisition &&
          <QuotationDynamicForm
          defaultData={defaultData}
          setValues={setValues}
          values={values}
          requisitionItems={selectedRequisition?.items}
          />
        }
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Processing..." : "Resubmit"}
        </button>
      </div>
          </>
        }
    </form>
  );
};

export default ResubmitRequisition;
