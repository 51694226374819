import DashboardWrapper from "../../../components/DashboardWrapper"
import Breadcrumbs from "../../../parts/Breadcrumbs"

const CreditNotesPage = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Credit Notes' />
        {/* body */}
      </>
    </DashboardWrapper>
  )
}

export default CreditNotesPage