import React, { useEffect, useState } from 'react'
import {  employeePersonalDefaultData } from '../../../../constants'
import useEmployees from '../../../../hooks/useEmployees'
import Validator from '../../../../utils/Validator'
import Input from '../../../global/Input'
import InputSelect from '../../../global/InputSelect'
import InputDate from '../../../global/InputDate'
import ImagePicker from '../../../global/ImagePicker'
import useRoles from '../../../../hooks/useRoles'
import { updateLocalStorage } from './dataPersistenceHelper'

const PersonalDetails = () => {
  const [personalDetails, setPersonalDetails] = useState(employeePersonalDefaultData);
  const validator = new Validator();
  const { storeEmployeePersonalDetails, next } = useEmployees();
  const { formattedRoles } = useRoles();

  useEffect(()=>{
    const userData = localStorage.getItem('newUser');
    if(userData){
      const data = JSON.parse(userData)
      personalDetails.first_name.data = data.first_name
      personalDetails.middle_name.data = data.middle_name
      personalDetails.last_name.data = data.last_name
      personalDetails.profile_pic.data = data.profile_pic
      personalDetails.profile_pic.data = data.profile_pic
      personalDetails.password.data = data.password
      personalDetails.email.data = data.email
      personalDetails.mobile_phone.data = data.mobile_phone
      personalDetails.role_id.data = data.role_id
      personalDetails.place_of_birth.data = data.place_of_birth
      personalDetails.date_of_birth.data = data.date_of_birth
      personalDetails.gender.data = data.gender
      personalDetails.national_id_no.data = data.national_id_no
    }
  },[])

  const handleImageChange = (e: any) => {
    console.log(e)
    setPersonalDetails({ ...personalDetails, ...{ profile_pic: { error: false, data: e } } });
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e
   if (value !== '') {
      // update the state
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: false, data: value } } });
    } else  {
      // indicate an error
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: true, data: value } } });
    }
  }

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box

    if (value !== '') {
      // update the state
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPersonalDetails({ ...personalDetails, ...{ [name]: { ...personalDetails[name], error: true, data: value } } });
    }
  };



  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validator.checkForEmptyFields(personalDetails, setPersonalDetails)) {
      const data = {
        first_name: personalDetails.first_name.data,
        middle_name: personalDetails.middle_name.data,
        last_name: personalDetails.last_name.data,
        profile_pic: personalDetails.profile_pic.data,
        password: personalDetails.password.data,
        email: personalDetails.email.data,
        mobile_phone: personalDetails.mobile_phone.data,
        role_id: personalDetails.role_id.data,
        place_of_birth: personalDetails.place_of_birth.data,
        date_of_birth: personalDetails.date_of_birth.data,
        gender: personalDetails.gender.data,
        national_id_no: personalDetails.national_id_no.data
      }
      updateLocalStorage(data);
      storeEmployeePersonalDetails(data);
      next();
    }
  }

 
  return (
    <form>
    <div className="form-row">
      <div className="col-sm-6">
        <div className="row flex-column m-0 mb-4">
          <label className="font-semibold text-sm">Profile Picture</label>
          <ImagePicker handleChange={handleImageChange}/>
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="col-sm-6">
        <Input
          name="first_name"
          handleChange={handleChange}
          label="First Name"
           value={personalDetails.first_name.data}
           error={personalDetails.first_name.error}
          errorMessage="Please provide the first name"
          required
        />
      </div>
      <div className="col-sm-6">
        <Input
          name="middle_name"
          handleChange={handleChange}
          label="Middle Name"
          value={personalDetails.middle_name.data}
        />
      </div>
      <div className="col-sm-6">
        <Input
          name="last_name"
          handleChange={handleChange}
          label="Last Name"
           value={personalDetails.last_name.data}
           error={personalDetails.last_name.error}
          errorMessage="Please provide the last name"
          required
        />
      </div>
      <div className="col-sm-6">
        <Input label='NIN Number' name='national_id_no' handleChange={handleChange} value={personalDetails.national_id_no.data} error={personalDetails.national_id_no.error} errorMessage="Please provide the national id number" required />
      </div>
    </div>

    <div className="form-row">
    <div className="col-sm-6">
        <Input
          name="email"
          handleChange={handleChange}
          label="Email Address"
          type="email"
           value={personalDetails.email.data}
           error={personalDetails.email.error}
          errorMessage="Please provide a valid email address"
          required
        />
      </div>
      <div className="col-sm-6">
        <Input
          name="password"
          type="password"
          handleChange={handleChange}
          label="Password"
           value={personalDetails.password.data}
           error={personalDetails.password.error}
          errorMessage="Please provide the user's password"
          required
        />
      </div>
    </div>

    <div className="form-row">
      <div className="col-sm-6">
        <Input
          name="mobile_phone"
          type="tel"
          handleChange={handleChange}
          label="Mobile Phone Number"
           value={personalDetails.mobile_phone.data}
           error={personalDetails.mobile_phone.error}
          errorMessage="Please provide the user's mobile_phone number"
          required
        />
      </div>
      <div className="col-sm-6">
        <InputSelect
            name="role_id"
            handleChange={handleSelectChange}
            label="Role"
            data={formattedRoles}
            id="role"
             value={{label:personalDetails.role_id.data, value: personalDetails.role_id.data }}
             error={personalDetails.role_id.error}
            errorMessage="Please provide the user's role"
            required />
      </div>
    </div>

    <div className="form-row">
    <div className="col-sm-6">
        <InputDate
          name="date_of_birth"
          handleChange={handleSelectChange}
          label="Date of birth"
          id="date_of_birth"
           value={personalDetails.date_of_birth.data}
           error={personalDetails.date_of_birth.error}
          errorMessage="Please provide the user's date of birth"
          required
          maxData={18}
        />
      </div>
      <div className="col-sm-6">
        <Input label='Place of birth' name='place_of_birth' handleChange={handleChange} value={personalDetails.place_of_birth.data} error={personalDetails.place_of_birth.error} errorMessage="Please provide the place of birth" required />
      </div>
    </div>

    <div className="form-row">
      <div className="col-sm-6">
        <InputSelect
          name="gender"
          handleChange={handleSelectChange}
          label= "Gender"
          data={[
            { value: "Male", label: "Male" },
            { label: "Female", value: "Female" },
          ]}
          id="gender"
           value={{label: personalDetails.gender.data , value : personalDetails.gender.data}} 
           error={ personalDetails.gender.error }
           errorMessage="Please provide the user's gender"
           required
        />
      </div>
    </div>

    <div className="form-row m-0 pb-4 pt-3 justify-content-end">
      <button type='button' className='btn  btn-info' onClick={handleNext}>
        Next
      </button>
    </div>
  </form>
  )
}

export default PersonalDetails