import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import {
  setProposals, setProposal
} from "../redux/reducer/accounting";

const useProposals = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { proposals, proposal } = useSelector(
    (state: RootState) => state.accounting
  );

  useEffect(() => {
    if (proposals.length > 0) {
      setLoading(false);
      return;
    }

    doGet("proposals")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setProposals(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    doGet("proposals")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setProposals(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProposal = (id: number) => {
    setLoading(true);
    doGet(`proposal/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setProposal(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    proposals,
    loading,
    refresh,
    getProposal,
    proposal
  };
};

export default useProposals
