import React, { useEffect, useState } from "react";
import useOrganisation from "../../hooks/useOrganisation";
import useQuotations from "../../hooks/useQuotations";
import { EllipsisLoader } from "../../components/global/MyLoaders";
import ReactToPrint from "react-to-print";
import Logo from "../../assets/images/logos/ktcu.jpeg";

// import 'bootstrap/dist/css/bootstrap.min.css';

interface QuotationProps {
  id: any;
}

const ViewBid = ({ id }: QuotationProps) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const handlePrint = () => {
    window.print();
  };
  const componentRef = React.useRef(null);
  const { organisation } = useOrganisation();

  const { getQuotation, loading, quotation } = useQuotations();
  useEffect(() => getQuotation(id), []);

  useEffect(() => {
    console.log("zaq", quotation);
    if (quotation?.items) {
      let total = 0;
      quotation.items.forEach((item: any) => {
        total += Number(item.total);
      });
      setTotalAmount(total);
    }
  }, [quotation]);

  return loading || quotation.length < 1 ? (
    <EllipsisLoader />
  ) : (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-end mb-10">
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary ml-auto mr-3">
                      Print Quotation
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="container" ref={componentRef}>
            <div className="row">
              <div className="col">
                <img src={Logo} alt="" className="h-8" />
                <h5>{organisation.name}</h5>
                <p>{organisation.email}</p>
                <p>{organisation.phone}</p>
              </div>
              <div className="col text-right">
                <h2>QUOTATION</h2>
                <p>
                  <strong>Ref No:</strong> {quotation?.reference_no}
                </p>
                <p>
                  <strong>Issue Date:</strong> {quotation?.issue_date}
                </p>
                <p>
                  <strong>Expiry Date:</strong> {quotation?.expiry_date}
                </p>
                <p>Quotation Number: {quotation.quotation_no}</p>
                <p>Net Amount: {quotation?.net_amount}</p>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">
                <h4>Customer Details:</h4>
                <p>
                  <strong>Name:</strong> {quotation?.customer.name}
                </p>
                <p>
                  <strong>Phone:</strong> {quotation?.customer.phone_no}
                </p>
                <p>
                  <strong>Email:</strong> {quotation?.customer.email}
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotation?.items?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item?.item_id}</td>
                        <td></td>
                        <td>{item?.quantity}</td>
                        <td>{item?.rate}</td>
                        <td>
                          {Number(item?.quantity * item?.rate).toLocaleString()}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>{Number(totalAmount).toLocaleString()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBid;
