import DashboardWrapper from "../../../components/DashboardWrapper"
import Breadcrumbs from "../../../parts/Breadcrumbs"

const ItemsPage = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Items' />
        {/* body */}
      </>
    </DashboardWrapper>
  )
}

export default ItemsPage