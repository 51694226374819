import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers, getCustomer } from "../api/customers";
import { RootState } from "../redux/app/store";
import {
  setCustomers,
  setSelectedCustomer,
  setRequisition,
  setRequisitions,
} from "../redux/reducer/customer";
import { doGet } from "../api";

const useCustomers = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { customers, selectedCustomer, requisition, requisitions } =
    useSelector((state: RootState) => state.customer);

  useEffect(() => {
    if (Object.keys(customers).length > 0) {
      setLoading(false);
      return;
    }

    getAllCustomers()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCustomers(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getAllCustomers()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCustomers(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSelectedCustomer = (id: number) => {
    setLoading(true);
    getCustomer(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedCustomer(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRequisitions = (status?: any) => {
    const URL = status ? "customer_requisitions?status=" + status : "customer_requisitions";
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRequisitions(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRequisition = (req_id?: number) => {
    const URL = "customer_requisitions/" + req_id;
    setLoading(true);
    doGet(URL)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setRequisition(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshRequisitions = () => {
    getRequisitions(); 
  }

  return {
    customers,
    loading,
    refresh,
    getSelectedCustomer,
    selectedCustomer,
    getRequisitions,
    getRequisition,
    refreshRequisitions,
    requisition,
    requisitions,
  };
};

export default useCustomers;
