import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";

import AddFunder from "./AddFunder";
import useFunders from "../../../hooks/useFunders";
import EditFunder from "../../settings/funders/EditFunder";

const ListFunders = () => {
  const { funders, loading, refresh } = useFunders();
  const [isOpen, setIsOpen] = useState(false);
  //const [funders, setFunders] = useState([]);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    //const { value } = e.target as HTMLInputElement;
    //const results = projects.data.filter((res: any) => String(res?.project_name).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()));
    //setFunders(results);
  };

  const applicationColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Project Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 2,
      name: "Telephone",
      selector: (row) => row.telephone,
      reorder: true,
      sortable: true,
      grow: 2,
    },
    {
      id: 3,
      name: "Email",
      selector: (row) => row.email ?? "No email",
      reorder: true,
      sortable: true,
      grow: 2,
    },
    {
      button: true,
      cell: (row) => (
        <div className="row">
          <Link
            to={`/projects/implementing-partners/${row.id}`}
            className="btn btn-sm btn-primary mx-1"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <button
            className="btn btn-sm btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(row.id);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>

          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-project";
      modalTitle = "Edit Funder";
      ModalToRender = EditFunder;

      break;
    case 3:
      modalId = "delete-implementing partner";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-new-funder";
      modalTitle = "Add New Implementing Partner";
      ModalToRender = AddFunder;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`projects/${selectedId}/delete`}
            refresh={refresh}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}
                  >
                    Add Implementing Partner
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            {/* table */}
            <div className="table-responsive mt-15">
              {loading ? (
                <EllipsisLoader />
              ) : (
                <MyDataTable
                  data={funders}
                  columns={applicationColumnData}
                  title=""
                />
              )}
            </div>
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListFunders;
