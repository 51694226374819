import React, { useEffect, useState } from "react";
import useBidStages from "../../../../hooks/useBidStages";
import Input from "../../../global/Input";
import toast from "react-hot-toast";
import { doCreate } from "../../../../api";
import { successToastOptions, errorToastOptions, ERRORMESSAGE, bidStagesDefaultData } from "../../../../constants";
import { formatErrorMessage } from "../../../../helpers";
import Validator from "../../../../utils/Validator";

interface BidStageProps {
  id: any;
  onCancel: ()=> void
}

const EditBidStage = ({ id, onCancel }: BidStageProps) => {
  const { getBidStage, loading, refresh } = useBidStages();
  const [bidStage, setBidStage] = useState(bidStagesDefaultData)
  const validator = new Validator()

  useEffect(() => getBidStage(id), []);

  useEffect(() => {
    console.log('zaq', bidStage)
  }, [bidStage]);


  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBidStage({
        ...bidStage,
        ...{
          [name]: { ...bidStage[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setBidStage({
        ...bidStage,
        ...{
          [name]: { ...bidStage[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(bidStage, setBidStage)) {
        //setLoading(true);
        const data = {
          id: id,
          name: bidStage.name.data
        };

        doCreate("bid_stages", data)
          .then((response) => {
            if (response.data.success === true) {
              toast.success(
                "Your bid stage has been successfully submitted",
                successToastOptions
              );
              refresh();

              setTimeout(() => {
                onCancel();
              }, 1000);
            } else {
              toast.error(
                formatErrorMessage(response.data.data, response.data.message),
                errorToastOptions
              );
            }
          })
          .catch(() => {
            toast.error(ERRORMESSAGE, errorToastOptions);
          })
          .finally(() => {
            //setLoading(false);
          });
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <Input
            type="text"
            id="bid_stage"
            name="name"
            label="New Bid Stage Name"
            handleChange={handleChange}
            value={bidStage.name.data}
            error={bidStage.name.error}
            errorMessage="Please enter the new bid stage name"
            required
          />
        </div>
        
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </form>
  );
};

export default EditBidStage
