import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  accountChartDefaultData,
  errorToastOptions,
  subCategoryDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccountCategories from "../../../hooks/useAccountCategories";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputSelect from "../../global/InputSelect";
import Textarea from "../../global/Textarea";
import useSubCategories from "../../../hooks/useSubCategories";
import { EllipsisLoader } from "../../global/MyLoaders";

interface IAddAccountChartProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddSubCategories = ({ closeModal, refresh }: IAddAccountChartProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [account, setAccount] = useState(subCategoryDefaultData);
  const [selectedCategory, setSelectedCategory] = useState(0)

  const { loading, categories, getSubCategories, subCategories, getCategories } = useSubCategories();

  useEffect(() => {getCategories(); getSubCategories()}, []);

  useEffect(()=> console.log('cats', categories), [])

  useEffect(()=> console.log('subs', subCategories), [])

  const validator = new Validator();

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleCategorySelectChange = (e: any) => {
    const { name, value } = e;
    console.log(e)
    setSelectedCategory(value);

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setAccount({
        ...account,
        ...{ [name]: { ...account[name], ...{ error: true, data: value } } },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(account, setAccount)) {
      setFormLoading(true);

      const data = {
        account_category_id: account.account_category_id.data,
        account_sub_category_id: account.account_sub_category_id.data,
        sub_cat_name: account.sub_cat_name.data,
        description: account.description.data,
        sub_cat_code: account.sub_cat_code.data,
      };

      doCreate("account-subcategpories/create", data)
        .then((response) => {  
          if (response.data.success === true) {
            toast.success(
              "New sub category created successfully",
              successToastOptions
            );
            getCategories();
            refresh();

            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      console.log(account);
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={categories?.map((res: any) => ({
              value: res?.id,
              label: res?.cat_name,
            }))}
            label="Category"
            id="account_category_id"
            name="account_category_id"
            required
            handleChange={handleCategorySelectChange}
          />
        </div>
        <div className="col-sm-6">
          <InputSelect
            data={selectedCategory !== 0 ? categories[selectedCategory-1].sub_categories?.map((res: any) => ({
              value: res?.id,
              label: res?.sub_cat_name,
            })): subCategories?.map((res: any) => ({
              value: res?.id,
              label: res?.sub_cat_name,
            })) }
            label=" Sub Category"
            id="account_sub_category_id"
            name="account_sub_category_id"
            handleChange={handleSelectChange}
          />
        </div>
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="sub_cat_name"
            label="New Sub Category Name"
          />
        </div>
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="sub_cat_code"
            label="Sub Account no"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            handleChange={handleChange}
            name="description"
            label="Description"
            required
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddSubCategories;
