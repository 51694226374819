import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { createNewFunder } from '../../../api/projects';
import { ERRORMESSAGE, errorToastOptions, funderDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useProjects from '../../../hooks/useProjects';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';

interface ICreateProjectProps {
  closeModal: () => void;
}

const AddFunder = ({ closeModal: onCancel }: ICreateProjectProps) => {
  const [funder, setFunder] = useState(funderDefaultData);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();

  const { refresh } = useProjects();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setFunder({ ...funder, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setFunder({ ...funder, ...{ [name]: { error: true, data: value } } });
    }
  };


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(funder, setFunder)) {
      setLoading(true);

      const data = {
        name: funder.name.data,
        telephone: funder.telephone.data,
        email: funder.email.data,
        telephone_2: funder.telephone_2.data,
      }

      // console.log(data); return;

      try {
        const newFunder = await createNewFunder(data);
        if (newFunder.data.success === true) {
          toast.success('New implementing partner saved successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newFunder.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Implementing Partner Name' handleChange={handleChange} value={funder.name.data} error={funder.name.error} errorMessage="Please provide the implementing partner name" required />
        </div>
        <div className="col-sm-6">
          <Input name='telephone' label='Telephone' handleChange={handleChange} value={funder.telephone.data} error={funder.telephone.error} errorMessage="Please provide the funder telephone number" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='telephone_2' label='Telephone 2' handleChange={handleChange} value={funder.telephone_2.data} error={funder.telephone_2.error} errorMessage="Please provide the funder secondary telephone number" />
        </div>
        <div className="col-sm-6">
          <Input name='email' label='email' handleChange={handleChange} value={funder.email.data} error={funder.email.error} errorMessage="Please provide the funder email" required />
        </div>
      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default AddFunder