/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { ReactElement } from "react";
import Select, { ActionMeta, StylesConfig } from "react-select";
import { iSelectProps } from "../../utils/interfaces";

type optionsType = { value: string | number; label: string };

const InputSelect = ({
  label,
  required,
  id,
  error,
  errorMessage,
  data,
  name,
  withNoLabel,
  handleChange,
  multiple,
  value,
  disabled,
}: iSelectProps): ReactElement => {
  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      padding: "0.2rem",
      ":focus": { outline: "2px solid #4bced3 !important" },
      ":hover": { borderColor: error ? "red" : "#4bced3" },
      boxShadow: "0 0 0 1px # #4bced3",
      borderColor: error ? "red" : "#eee",
    }),
  };
  const change = (option: unknown, actionMeta: ActionMeta<unknown>) => {
    // handleChange(option?.value)!;
    const newOption = option as optionsType;
    const resp = {
      selectedOptions: newOption,
      value: newOption.value,
      name: actionMeta.name,
    };
    if (handleChange) {
      handleChange(resp);
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={id} className="font-semibold text-sm">
        {label}
        {withNoLabel ? null : required ? (
          <span className="text-danger text-sm font-medium mb-3">*</span>
        ) : (
          <span className="text-gray-500 ml-3 text-xs">(Optional)</span>
        )}
      </label>
      <Select
        isDisabled={disabled}
        options={data}
        styles={colourStyles}
        name={name}
        onChange={(option, e) => change(option, e)}
        isMulti={multiple}
        defaultValue={value?.label !== "" ? value : null}
      />
      {error && <small className="text-danger">{errorMessage}</small>}
    </div>
  );
};

export default InputSelect;
