import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import Textarea from "../../global/Textarea";
import useCashRequisition from "../../../hooks/useCashRequisition";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";

interface IAcceptRequisitionProps {
  onCancel: () => void;
  id: number;
  refresh: () => void;
}

const ReviewCashRequisition = ({
  onCancel,
  id,
  refresh,
}: IAcceptRequisitionProps) => {
  const [comment, setComment] = useState({ error: false, data: "" });
  const [formLoading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { getCashRequisition, selectedCashRequisition, loading } =
    useCashRequisition();

  useEffect(() => {
    const req = getCashRequisition(id)
    console.log('creq', req)
  }, [id]);

  useEffect(()=>{
    setTimeout(()=>{
        console.log('items', selectedCashRequisition)
        setSelectedItems( selectedCashRequisition?.items?.map((item: any) => ({
          ...item,
          selected: true,
          quantity: item.quantity,
        })) || [])
        //setIsLoadingData(false)
    }, 500)
  },[selectedCashRequisition])

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target as HTMLInputElement;
    if (value !== "") {
      setComment({ error: false, data: value });
    } else {
      setComment({ error: false, data: "" });
    }
  };

  const handleCheckboxChange = useCallback((itemId: number) => {
    setSelectedItems((prevItems: any) =>
      prevItems.map((item: any) =>
        item.id === itemId ? { ...item, selected: !item.selected } : item
      )
    );
  }, []);

  const handleQuantityChange = useCallback(
    (itemId: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSelectedItems((prevItems: any) =>
        prevItems.map((item: any) =>
          item.id === itemId ? { ...item, quantity: value } : item
        )
      );
    },
    []
  );

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (comment.data.length > 6) {
      const data = {
        comment: comment.data,
        state_id: 2,
        cash_requisition_id: id,
      };

      setLoading(true);

      doCreate("cash-requisitions/" + id + "/forward_after_review", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your review has been successfully saved",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setComment({ error: true, data: comment.data });
      return;
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            withNoLabel
            name="comment"
            label="Comment"
            handleChange={(e) =>
              setComment({ error: false, data: e.target.value })
            }
            required
            errorMessage="Please provide a comment"
            value={comment.data}
            error={comment.error}
          />
        </div>
      </div>
      {selectedItems?.map((item: any) => (
        <div key={item.id} className="form-row mb-4">
          <div className="col-lg-2">
            <label className="form-label"></label>
            <input
              className="form-control mt-4"
              type="checkbox"
              value={item.id}
              checked={item.selected}
              onChange={() => handleCheckboxChange(item.id)}
            />
          </div>
          <div className="col-lg-5">
            <label className="form-label">Item Name</label>
            <input className="form-control" value={item?.name} disabled/>
          </div>
          <div className="col-lg-3">
            <label className="form-label">Amount</label>
            <input
              type="number"
              className="form-control"
              value={item.amount}
              onChange={(e) => handleQuantityChange(item.id, e)}
            />
          </div>
         
        </div>
      ))}
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="button"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" disabled={loading} className="btn  btn-info" onClick={handleSubmit}>
          {loading ? "Processing..." : "Review"}
        </button>
      </div>
    </form>
  )
};

export default ReviewCashRequisition;
