import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactToPrint from "react-to-print";
import useRequisition from "../../../hooks/useRequisition";
import { EllipsisLoader } from "../../global/MyLoaders";
import Logo from "../../../assets/images/logo.svg";
import useOrganisation from "../../../hooks/useOrganisation";
import { successToastOptions } from "../../../constants";
import toast from "react-hot-toast";

interface Requisition {
  id: any;
}

const ViewRequisition = ({ id }: Requisition) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const handlePrint = () => {
    window.print();
  };
  const componentRef = React.useRef(null);
  const {organisation} = useOrganisation();

  const { getRequisition, requisition, loading } = useRequisition();
  useEffect(() => getRequisition(id), []);

  useEffect(() => {
    if (requisition?.items) {
      let total = 0;
      requisition.items.forEach((item: any) => {
        total += Number(item.total);
      });
      setTotalAmount(total);
    }
  }, [requisition]);


  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-end mb-10">
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary ml-auto mr-3">
                       Print Order
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="container" ref={componentRef}>
            <div className="row">
              <div className="col">
                <img src={Logo} alt="" className="h-8" />
                <h5>{organisation.name}</h5>
                <p>{organisation.email}</p>
                <p>{organisation.phone}</p>
              </div>
              <div className="col text-right">
                <h2>REQUISITION</h2>
                <p>
                  <strong>Ref No:</strong> {requisition?.reference_no}
                </p>
                <p>
                  <strong>Date:</strong> {requisition?.requisition_date}
                </p>
                <p>Status: {requisition.status}</p>
                <p>Type: {requisition?.type}</p>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">
                <h4>Requested By:</h4>
                <p>
                  <strong>Name:</strong> {requisition?.requester?.first_name+" "+requisition?.requester?.last_name}
                </p>
                <p>
                  <strong>Phone:</strong> {requisition?.requester?.mobile_phone}
                </p>
                <p>
                  <strong>Email:</strong> {requisition?.requester?.email}
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requisition?.items?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item?.item.name}</td>
                        <td></td>
                        <td>{item?.quantity}</td>
                        <td>{item?.rate}</td>
                        <td>
                          {Number(item?.quantity * item?.rate).toLocaleString()}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>{Number(totalAmount).toLocaleString()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRequisition;
