import DashboardWrapper from "../../../components/DashboardWrapper"
import ListProposals from "../../../components/sales/proposals/ListProposals"
import Breadcrumbs from "../../../parts/Breadcrumbs"

const ProposalsPage = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Proposals' />
        {/* body */}
        <ListProposals />
      </>
    </DashboardWrapper>
  )
}

export default ProposalsPage