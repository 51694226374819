import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import { setBids, setSelectedBid } from '../redux/reducer/bids';

const useBids = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    bids, selectedBid
  } = useSelector(
    (state: RootState) => state.bidStages,
  );

  useEffect(() => {

    if (bids.length > 0) {
      setLoading(false);
      return;
    }

    doGet('bid-analysis').then((response) => {
      if (response.status === 200) {
        dispatch(setBids(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    doGet('bid-analysis').then((response) => {
      if (response.status === 200) {
        dispatch(setBids(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getBid = (id: number) => {
    setLoading(true);
    doGet('bid-analysis/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedBid(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { loading, refresh, selectedBid, bids, getBid }
}

export default useBids