import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import useRequisition from "../../../hooks/useRequisition";
import {
  formatBillStatus,
  formatRequisitionSelectData,
  formatSupplierSelectData,
} from "../../../utils/helpers";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddBidAnalysis from "./AddBidAnalysis";
import EditBidAnalysis from "./EditBidAnalysis";
import { Link } from "react-router-dom";
import InputSelect from "../../global/InputSelect";
import { filterArray } from "../../../utils/filterarray";
import useBids from "../../../hooks/useBids";
import useSuppliers from "../../../hooks/useSuppliers";
import { Filters } from "../../../types/Filter";
import DeclineBid from "../bids/DeclineBid";
import ApproveBid from "../bids/ApproveBid";

const ListBidAnalysis = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { loading, requisitions, getBidAnalysis } = useRequisition();
  const { bids, refresh } = useBids();
  const { suppliers } = useSuppliers();
  const [filters, setFilters] = useState<Filters>({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBidAnalysis(), []);

  const [sortedBids, setSorteBids] = useState([])

  useEffect(()=>{
    if(bids.length > 0) {
      const sortedBids: any  = [...bids].sort((a: {id:number}, b: {id:number}) => b.id - a.id)
      setSorteBids(sortedBids)
    }
  }, [bids])

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Requisition ID",
      selector: (row) => row.requisition_id,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "Supplier",
      selector: (row) => row?.supplier?.name ?? "No Supplier",
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 4,
      name: "Issue Date",
      selector: (row) => row.date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 5,
      name: "Stage",
      selector: (row) => row.stage?.name,
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      id: 6,
      name: "Status",
      // @ts-ignore
      selector: (row) => (
        <span className={`badge ${formatBillStatus(row.status_id).className}`}>
          {" "}
          <i className={`fa ${formatBillStatus(row.status_id).icon}`}></i>{" "}
          {formatBillStatus(row.status_id).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className="row">
          <Link className="btn btn-sm btn-info mr-1" to={`/procurement/bids/view/${row.id}`}>
            <i className="fa fa-eye"></i>
          </Link>
          <button
            className="btn btn-sm btn-success mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleApproveClick(row.id)
            }}
          >
            <i className="fa fa-check"></i>
          </button>
          <button className="btn btn-sm btn-outline-danger">
            <i
              className="fa fa-times"
              onClick={(e) => {
                e.stopPropagation();
                handleDeclineClick(row.id)
              }}
            ></i>
          </button>
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeclineClick = (id: number) => {
    setOpenModal(4); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleApproveClick = (id: number) => {
    setOpenModal(5); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };


  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-bid-analysis";
      modalTitle = "Edit Bid ";
      ModalToRender = EditBidAnalysis;

      break;
    case 3:
      modalId = "delete-bid";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
      case 4:
        modalId = "decline-bid";
        modalTitle = "Decline Bid";
        ModalToRender = DeclineBid;
      break;
      case 5:
        modalId = "approve-bid";
        modalTitle = "Approve Bid";
        ModalToRender = ApproveBid;
      break;
    default:
      modalId = "add-new-bid-analysis";
      modalTitle = "Add New Bid";
      ModalToRender = AddBidAnalysis;
      break;
  }

  const handleSelect = (e: any) => {
    const { name, value } = e;
    setFilters((prevFilters: Filters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            onCancel={handleModalClick}
            id={selectedId}
            closeModal={handleModalClick}
            path={`bid-analysis/${selectedId}/delete`}
            refresh={refresh}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block z-10">
                <InputSelect
                  data={formatRequisitionSelectData(requisitions)}
                  id="filter "
                  label="Filter By Requisition"
                  name="requisition_id"
                  required
                  handleChange={handleSelect}
                  errorMessage="Please select the requisitions"
                  // error={purchaseOrder.purchase_requisition_id.error}
                />
              </div>
              <div className="d-block z-10">
                <InputSelect
                  data={formatSupplierSelectData(suppliers)}
                  id="filter "
                  label="Filter By Supplier"
                  name="supplier_id"
                  required
                  handleChange={handleSelect}
                  errorMessage="Please select the supplier"
                  // error={purchaseOrder.purchase_requisition_id.error}
                />
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix"></div>
              </div>
            </div>
            {/* table */}
            <MyDataTable
              columns={columns}
              data={filterArray(sortedBids, filters)}
              title=""
            />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListBidAnalysis;
