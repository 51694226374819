import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doGet } from "../../../api";
import { createNewActivity } from "../../../api/projects";
import {
  activityDefaultData,
  ERRORMESSAGE,
  errorToastOptions,
  priorityData,
  statusData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useActivities from "../../../hooks/useActivities";
import useProjects from "../../../hooks/useProjects";
import { formatAcountSelectData, getFormData } from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import TextEditor from "../../global/TextEditor";

interface ICreateActivityProps {
  onCancel: () => void;
  projectId?: number;
  inRefresh?: () => void;
}

const CreateActivity = ({
  onCancel,
  projectId,
  inRefresh,
}: ICreateActivityProps) => {
  const [activity, setActivity] = useState(activityDefaultData);

  const [items, setItems] = useState<any>([]);
  const [budget, setBudget] = useState(0);
  const [loading, setLoad] = useState(false);
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();
  const {
    accounts,
    expenseAccounts,
    getExpenseAccount,
    cashAccounts,
    getCashAccount,
  } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getExpenseAccount(), [accounts]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCashAccount(), [accounts]);

  //remove the projectId if its set
  if (projectId) {
    delete activity["project_id"];
  }

  const { refresh } = useActivities();
  const { formattedProjectData, selectedProject } = useProjects();

  useEffect(() => {
    const budget_id = selectedProject?.project_budget?.budget_id;
    if (budget_id) {
      setBudget(budget_id);
      doGet(`budget/${budget_id}/get_budget_items`)
        .then((response) => {
          setItems(response.data.data);
        })
        .catch(() => {})
        .finally(() => {
          setLoad(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setActivity({
        ...activity,
        ...{ [name]: { error: false, data: value } },
      });
    } else {
      // indicate an error
      setActivity({ ...activity, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setActivity({
        ...activity,
        ...{ [name]: { error: false, data: value } },
      });
    } else {
      // indicate an error
      setActivity({ ...activity, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(activity, setActivity)) {
      setLoading(true);

      // const data = {
      //   activity_name: activity.activity_name.data,
      //   status: activity.status.data,
      //   activity_methodology: activity.activity_methodology.data,
      //   cost: activity.cost.data,
      //   description: activity.description.data,
      //   objectives: activity.objectives.data,
      //   project_id: projectId ? projectId : activity.project_id.data,
      //   account_chart_id: activity.account_chart_id.data,
      //   priority: activity.priority.data
      // }

      const data = getFormData(activity);

      // console.log(data); return;

      try {
        const newProject = await createNewActivity({
          ...data,
          ...{
            budget_id: budget,
            project_id: projectId ? projectId : activity.project_id.data,
          },
        });

        if (newProject.data.success === true) {
          toast.success(
            "New Activity created successfully",
            successToastOptions
          );
          if (inRefresh) {
            inRefresh();
          } else {
            refresh(); // refresh the hook store
          }

          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(newProject.data.data),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }
      setLoading(false);
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="activity_name"
            label="Activity Name"
            handleChange={handleChange}
            value={activity.activity_name.data}
            error={activity.activity_name.error}
            errorMessage="Please provide the activity name"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            name="activity_methodology"
            label="Activity Methodology"
            handleChange={handleChange}
            value={activity.activity_methodology.data}
            error={activity.activity_methodology.error}
            errorMessage="Please provide the activity methodology"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="cost"
            type="number"
            label="Activity cost"
            handleChange={handleChange}
            value={activity.cost.data}
            error={activity.cost.error}
            errorMessage="Please provide the activity cost"
            required
          />
        </div>

        <div className="col-sm-6">
          <InputSelect
            data={formatAcountSelectData(cashAccounts)}
            label="Fund Account"
            name="account_chart_id"
            id="account_chart_id"
            required
            handleChange={handleSelectChange}
            value={activity.account_chart_id.data}
            error={activity.account_chart_id.error}
            errorMessage="Please select the fund account"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect
            data={formatAcountSelectData(expenseAccounts)}
            label="Activities tracking account"
            name="activity_account"
            id="activity_account"
            required
            handleChange={handleSelectChange}
            value={activity.activity_account.data}
            error={activity.activity_account.error}
            errorMessage="Please select the activity account"
          />
        </div>
      </div>

      {projectId ? (
        <div className="form-row">
          <div className="col-sm-6">
            <InputSelect
              data={statusData}
              name="status"
              id="status"
              required
              handleChange={handleSelectChange}
              label="Activity status"
              value={activity.status.data}
              error={activity.status.error}
              errorMessage="Please provide the activity status"
            />
          </div>
          <div className="col-sm-6">
            <InputSelect
              data={priorityData}
              name="priority"
              id="priority"
              required
              handleChange={handleSelectChange}
              label="Activity priority"
              value={activity.priority.data}
              error={activity.priority.error}
              errorMessage="Please provide the activity priority"
            />
          </div>
        </div>
      ) : (
        <div className="form-row">
          <div className="col-sm-6">
            <InputSelect
              data={formattedProjectData}
              name="project_id"
              id="project_id"
              required
              handleChange={handleSelectChange}
              label="Project Name"
              value={activity.project_id.data}
              error={activity.project_id.error}
              errorMessage="Please provide the project name"
            />
          </div>
          <div className="col-sm-6">
            <InputSelect
              data={statusData}
              name="status"
              id="status"
              required
              handleChange={handleSelectChange}
              label="Activity status"
              value={activity.status.data}
              error={activity.status.error}
              errorMessage="Please provide the activity status"
            />
          </div>
        </div>
      )}

      <div className="form-row">
        <div className="col-sm-6">
          <TextEditor
            name="description"
            label="Activity Description"
            handleChange={handleSelectChange}
            required
            value={activity.description.data}
            error={activity.description.error}
            errorMessage="Please provide the activity description"
          />
        </div>
        <div className="col-sm-6">
          <TextEditor
            name="objectives"
            label="Objectives"
            handleChange={handleSelectChange}
            required
            value={activity.objectives.data}
            error={activity.objectives.error}
            errorMessage="Please provide the activity objectives"
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default CreateActivity;
