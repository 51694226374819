import React from 'react';
import { useField } from "formik";
import classNames from 'classnames';
interface IInputProps {
  label: string,
  name: string,
  value?: string | number,
  error?: boolean | any,
  errorMessage?: string,
  type?: string,
  placeholder?: string,
  required?: boolean,
  noLabel?: boolean,
  readonly?: boolean,
  id?: any,
  [rest: string]: any,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = ({ label, name, value, handleChange, error, errorMessage, type = "text", placeholder, required, noLabel, readonly, rest, id }: IInputProps) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label} {!noLabel ? required ? <span className="text-danger text-sm font-medium mb-3">*</span> : <span className="text-gray-500 ml-3 text-xs">(Optional)</span> : null}</label>
      <input type={type} className={`form-control ${error && 'border-danger'}`} id={id} name={name} aria-describedby={name} placeholder={placeholder} onChange={handleChange} value={value === null ? '' : value} readOnly={readonly} {...rest} />
      {error && (
        <small id={name} className="form-text text-danger">{errorMessage}</small>
      )}
    </div>
  )
}

export const InputNoLabel = ({ ...rest }: any) => {
  return (
    <div className="form-group">
      <input t className={`form-control`} {...rest} />

    </div>
  )
}

export const FormInput = ({ label, ...rest }: any) => {
  const [field, meta] = useField<any>(rest);
  const inputClasses = classNames(
    "form-control",
    { "border-danger": meta.touched && meta.error }
  );
  return (
    <div className="form-group">
      <label
        htmlFor={rest.id || rest.name}
      >
        {label} <span className="text-danger text-sm font-medium mb-3">*</span>
      </label>
      <input className={inputClasses} {...rest} {...field} />
      {meta.touched && meta.error && (
        <small className="form-text text-danger">{meta.error}</small>
      )}
    </div>
  );
}

export const FormInputNoLabel = ({ ...rest }: any) => {
  const [field, meta] = useField<any>(rest);
  const inputClasses = classNames(
    "form-control",
    { "border-danger": meta.touched && meta.error }
  );
  return (
    <div className="form-group">
      <input className={inputClasses} {...rest} {...field} />
      {meta.touched && meta.error && (
        <small className="form-text text-danger">{meta.error}</small>
      )}
    </div>
  );
}

export const FormTextarea = ({ label, ...rest }: any) => {
  const [field, meta] = useField<any>({ ...rest, type: 'textarea' });
  const inputClasses = classNames(
    "form-control",
    { "border-danger": meta.touched && meta.error }
  );
  return (
    <div className="form-group">
      <label
        htmlFor={rest.id || rest.name}
      >
        {label} <span className="text-danger text-sm font-medium mb-3">*</span>
      </label>
      <textarea className={inputClasses} {...rest} {...field} />
      {meta.touched && meta.error && (
        <small className="form-text text-danger">{meta.error}</small>
      )}
    </div>
  );

}



export default Input