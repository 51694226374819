import React, { useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { ROUTES } from "./routes";

import MainPrivateRoute from "./MainPrivateRoute";
import MainNonPrivateRoute from "./MainNonPrivateRoute";
import NotFound from "../components/global/NotFound";
import FullClinicReport from "../pages/report/view/FullClinicReport";
import ViewIncomeStatementComparisonReport from "../pages/report/ViewISComparisonReport";
import FullProjectReport from "../pages/report/view/FullProjectReport";
import FullEmployeeReport from "../pages/report/view/FullEmployeeReport";
import ReportsMenu from "../pages/report/reports_menu";
import Settings from "../pages/settings/Settings";
import ActivityParameter from "../pages/settings/ActivityParameter";
import ActivityTeamRoles from "../pages/settings/ActivityTeamRoles";
import Funders from "../pages/settings/Funders";
import Clinics from "../pages/clinics/Clinics";
import ClinicDetails from "../pages/clinics/ClinicDetails";
import ClinicDoctors from "../pages/clinics/ClinicDoctors";
import ClinicAppointments from "../pages/clinics/ClinicAppointments";
import ClinicPatients from "../pages/clinics/ClinicPatients";
import ClinicTreatments from "../pages/clinics/ClinicTreatments";
import QuotationView from "../pages/procurement/ViewBid";
import AddEmployee from "../pages/hr/employee/AddEmployee";
import Users from "../pages/settings/Users";
import Departments from "../pages/settings/Departments";
import Roles from "../pages/settings/Roles";

// import ForgotPassword from '../pages/auth/ForgotPassword';
import Dashboard from "../pages/Dashboard";
import Stories from "../pages/App/Stories";
import Facts from "../pages/App/Facts";
import Tips from "../pages/App/Tips";
import Appointments from "../pages/doctor/Appointments";
import Treatments from "../pages/doctor/Treatments";
import Patients from "../pages/doctor/Patients";
import Activities from "../pages/projects/Activities";
import Projects from "../pages/projects";
import Categories from "../pages/settings/Categories";
import Teams from "../pages/projects/Teams";
import Programs from "../pages/settings/Programs";
import ProjectDetails from "../pages/projects/ProjectDetails";
import ViewActivityDetails from "../pages/projects/ViewActivityDetails";
import Reports from "../pages/report/Reports";
import BudgetComparisonDetails from "../pages/report/BudgetComparisonDetails";
import AccountTransactions from "../pages/report/view/AccountTransactions";
// import FullClinicReport from '../pages/report/view/FullClinicReport';
import BidView from "../pages/procurement/ViewBid";

import GenerateReport from "../pages/report/GenerateReport";
import Employees from "../pages/hr/employee/Employees";
import LeaveManagement from "../pages/hr/LeaveManagement";
import LeaveManagementView from "../pages/hr/LeaveManagementView";
import ViewEmployeeDetails from "../pages/hr/employee/ViewEmployeeDetails";
import PayrollManagement from "../pages/hr/payroll/PayrollManagement";
import AddPayroll from "../pages/hr/payroll/AddPayroll";
import Genarate from "../pages/hr/payroll/Genarate";
import ViewPersonalPayroll from "../pages/hr/payroll/ViewPersonalPayroll";
import StaffPerformance from "../pages/hr/StaffPerformance";
import ViewStory from "../pages/App/ViewStory";
import ViewFunFact from "../pages/App/ViewFunFact";
import ViewTreatmentDetails from "../pages/doctor/ViewTreatmentDetails";
import ViewCategory from "../pages/settings/ViewCategory";
import ViewProgram from "../pages/settings/ViewProgram";
import ApprovingCommittee from "../pages/settings/ApprovingCommittee";
import ReviewingCommittee from "../pages/settings/ReviewingCommittee";
import Requisition from "../pages/procurement/Requisition";
import ViewRequisitionDetails from "../pages/procurement/ViewRequisitionDetails";
import PurchaseOrder from "../pages/procurement/PurchaseOrder";
import BidAnalysis from "../pages/procurement/BidAnalysis";
import Quotation from "../pages/procurement/Bid";
import GoodsRecieved from "../pages/procurement/GoodsRecieved";
import ViewGoodsReceivedDetails from "../pages/procurement/ViewGoodsReceivedDetails";
import CashRequisition from "../pages/procurement/CashRequisition";
import ChartsOfAccount from "../pages/accounting/ChartsOfAccount";
import Invoices from "../pages/accounting/Invoices";
import Taxes from "../pages/accounting/Taxes";
import Pricing from "../pages/accounting/Pricing";
import Billing from "../pages/accounting/Billing";
import Stocks from "../pages/stocks/Stocks";
import Products from "../pages/stocks/Products";
import OutGoingStock from "../pages/stocks/OutGoingStock";
import Brands from "../pages/stocks/Brands";
import StockCategories from "../pages/stocks/Categories";
import Suppliers from "../pages/stocks/Suppliers";
import ProjectCategories from "../pages/projects/categories";
import ImplementingPartners from "../pages/projects/funders";
import NewBalanceSheetComparisonReport from "../components/accounting/balanceSheet/BSComparisonReport";
import ProposalsPage from "../pages/sales/proposals/Proposals";
import EstimatesPage from "../pages/sales/estimates/Estimates";
import ItemsPage from "../pages/sales/items/Items";
import CreditNotesPage from "../pages/sales/credit_notes/CreditNotes";
import PaymentsPage from "../pages/sales/payments/Payments";
import BidStages from "../pages/procurement/BidStages";
import Bids from "../pages/procurement/Bid";
const StockSettings = lazy(() => import("../pages/stocks/StockSettings"));
const Training = lazy(() => import("../pages/hr/Training"));
const Application = lazy(() => import("../pages/nonProfits/Applications"));
const Membership = lazy(() => import("../pages/nonProfits/Membership"));
const Volunteers = lazy(() => import("../pages/nonProfits/Volunteers"));
const Donors = lazy(() => import("../pages/nonProfits/Donors"));
const Procurement = lazy(() => import("../pages/procurement/Procurement"));
const Assets = lazy(() => import("../pages/assetsManagement/Assets"));
const View = lazy(() => import("../pages/assetsManagement/View"));
const AssetTypes = lazy(() => import("../pages/settings/AssetTypes"));
const MyProfile = lazy(() => import("../pages/settings/MyProfile"));
const OrganisationSettings = lazy(
  () => import("../pages/settings/OrganisationSettings")
);
const Locations = lazy(() => import("../pages/settings/Locations"));
const Incomestatement = lazy(
  () => import("../pages/accounting/Incomestatement")
);
const Transactions = lazy(() => import("../pages/accounting/Transactions"));
const Balancesheet = lazy(() => import("../pages/accounting/Balancesheet"));
const Schedules = lazy(() => import("../pages/accounting/Schedules"));
const Budget = lazy(() => import("../pages/accounting/Budget"));
const ViewBudgetDetails = lazy(
  () => import("../pages/accounting/ViewBudgetDetails")
);
const ViewPurchaseOrderDetails = lazy(
  () => import("../pages/procurement/ViewPurchaseOrderDetails")
);
const Units = lazy(() => import("../pages/settings/Units"));
const Announcements = lazy(() => import("../pages/announcements/Announcemts"));
const ViewAnnouncement = lazy(
  () => import("../pages/announcements/ViewAnnouncement")
);
const PaymentTypes = lazy(() => import("../pages/settings/PaymentTypes"));
const TransactionDetails = lazy(
  () => import("../pages/accounting/TransactionDetails")
);
const AccountDetails = lazy(() => import("../pages/accounting/AccountDetails"));
const SubCategories = lazy(() => import("../pages/accounting/SubCategories"));
const MoveToTop = lazy(() => import("./MoveToTop"));
const CashFlow = lazy(() => import("../pages/accounting/CashFlow"));
const TrialBalance = lazy(() => import("../pages/accounting/TrialBalance"));
const PayrollReports = lazy(() => import("../pages/accounting/PayrollReports"));
const BudgetReport = lazy(() => import("../pages/accounting/BudgetReport"));
const Equity = lazy(() => import("../pages/accounting/Equity"));
const Customers = lazy(() => import("../pages/customers/Customers"));
const CustomerRequisitions = lazy(
  () => import("../pages/customers/CustomerRequisition")
);
const ReceivingReport = lazy(
  () => import("../pages/report/generalReports/ReceivingReport")
);
const SchedulesPage = lazy(() => import("../pages/hr/schedules"));
const TimeSheetsPage = lazy(() => import("../pages/hr/TimeSheet"));
//new routes
const MonthlyActivities = lazy(() => import("../pages/hr/MonthyActivities"));
const ViewBid = lazy(() => import("../components/procurement/bids/ViewBid"));
const ViewFunder = lazy(
  () => import("../components/project/implementingFunder/ViewFunder")
);
const BalanceSheetSummary = lazy(
  () => import("../components/accounting/balanceSheet/BalanceSheetSummary")
);

const BalanceSheetDetails = lazy(
  () => import("../components/accounting/balanceSheet/BalanceSheetDetails")
);
// eslint-disable-next-line import/first
import { EllipsisLoader } from "../components/global/MyLoaders";

const Login = React.lazy(() => import("../pages/auth/Login"));

export default function AppRouter(): React.ReactElement {
  return (
    <Suspense fallback={EllipsisLoader}>
      <Router>
        <Switch>
          <MainPrivateRoute
            path={ROUTES.DASHBOARD}
            component={Dashboard}
            exact
          />
          <MainPrivateRoute path={ROUTES.CLINICS} component={Clinics} exact />
          <MainPrivateRoute
            path={ROUTES.CLINICDETAILS}
            component={ClinicDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CLINICDOCTORS}
            component={ClinicDoctors}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CLINICAPPOINTMENTS}
            component={ClinicAppointments}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VIEWQUOTATION}
            component={QuotationView}
            exact
          />
          <MainPrivateRoute path={ROUTES.VIEWBID} component={ViewBid} exact />
          <MainPrivateRoute
            path={ROUTES.PROPOSALS}
            component={ProposalsPage}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ESTIMATES}
            component={EstimatesPage}
            exact
          />
          <MainPrivateRoute path={ROUTES.ITEMS} component={ItemsPage} exact />
          <MainPrivateRoute path={ROUTES.BIDS} component={Bids} exact />
          <MainPrivateRoute
            path={ROUTES.PAYMENTS}
            component={PaymentsPage}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CREDITNOTES}
            component={CreditNotesPage}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CLINICTREATMENTS}
            component={ClinicTreatments}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CLINICPATIENTS}
            component={ClinicPatients}
            exact
          />
          <MainPrivateRoute path={ROUTES.STORIES} component={Stories} exact />
          <MainPrivateRoute
            path={ROUTES.VIEWSTORY}
            component={ViewStory}
            exact
          />
          <MainPrivateRoute path={ROUTES.FACTS} component={Facts} exact />
          <MainPrivateRoute
            path={ROUTES.VIEWFACTS}
            component={ViewFunFact}
            exact
          />
          <MainPrivateRoute path={ROUTES.TIPS} component={Tips} exact />
          <MainPrivateRoute
            path={ROUTES.APPOINTMENTS}
            component={Appointments}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.TREATMENTS}
            component={Treatments}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VIEWTREATMENT}
            component={ViewTreatmentDetails}
            exact
          />
          <MainPrivateRoute path={ROUTES.PATIENTS} component={Patients} exact />
          <MainPrivateRoute
            path={ROUTES.BSCOMPARISONREPORT}
            component={NewBalanceSheetComparisonReport}
            exact
          />
          <MainPrivateRoute path={ROUTES.PROJECTS} component={Projects} exact />
          <MainPrivateRoute
            path={ROUTES.VIEWFUNDER}
            component={ViewFunder}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ACTIVITIES}
            component={Activities}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CATEGORIES}
            component={Categories}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VIEWCATEGORIES}
            component={ViewCategory}
            exact
          />
          <MainPrivateRoute path={ROUTES.TEAMS} component={Teams} exact />
          <MainPrivateRoute path={ROUTES.PROGRAMS} component={Programs} exact />
          <MainPrivateRoute
            path={ROUTES.VIEWPROGRAM}
            component={ViewProgram}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.PROJECTDETAILS}
            component={ProjectDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BIDSTAGES}
            component={BidStages}
            exact
          />
          <MainPrivateRoute path={ROUTES.BIDS} component={BidView} exact />
          <MainPrivateRoute
            path={ROUTES.ACTIVITYDETAILS}
            component={ViewActivityDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REPORTS}
            component={ReportsMenu}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ISCOMPARISONREPORT}
            component={ViewIncomeStatementComparisonReport}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BUDGETCOMPARISONDETAILS}
            component={BudgetComparisonDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REPORTSVIEWCLINIC}
            component={FullClinicReport}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REPORTSVIEWPROJECT}
            component={FullProjectReport}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REPORTSVIEWEMPLOYEE}
            component={FullEmployeeReport}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ACCOUNTTRANSACTIONS}
            component={AccountTransactions}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REPORTSVIEW}
            component={GenerateReport}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.EMPLOYEES}
            component={Employees}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ADDEMPLOYEE}
            component={AddEmployee}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.EMPLOYEEDETAILS}
            component={ViewEmployeeDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.LEAVEMANAGEMENT}
            component={LeaveManagement}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.MONTHLYACTIVITES}
            component={MonthlyActivities}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.HRSCHEDULES}
            component={SchedulesPage}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.TIMESHEETS}
            component={TimeSheetsPage}
            exact
          />
          <MainPrivateRoute path={ROUTES.TRAINING} component={Training} exact />
          <MainPrivateRoute
            path={ROUTES.LEAVEMANAGEMENTDETAILS}
            component={LeaveManagementView}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.PAYROLLMANAGEMENT}
            component={PayrollManagement}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ADDPAYROLL}
            component={AddPayroll}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.GENERATEPAYROLL}
            component={Genarate}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VIEWPERSONALPAYROLL}
            component={ViewPersonalPayroll}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.STAFFPERFORMANCE}
            component={StaffPerformance}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REQUISITIONS}
            component={Requisition}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.IMPLEMENTINGPARTNERS}
            component={ImplementingPartners}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.PROJECTCATEGORIES}
            component={ProjectCategories}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REQUISITIONDETAILS}
            component={ViewRequisitionDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CASHREQUISITIONS}
            component={CashRequisition}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.INCOMESTATEMENT}
            component={Incomestatement}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REPORTSMENU}
            component={ReportsMenu}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.TRIALBALANCE}
            component={TrialBalance}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BUDGETREPORT}
            component={BudgetReport}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.PAYROLLREPORT}
            component={PayrollReports}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.TRANSACTIONS}
            component={Transactions}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BALANCESHEET}
            component={Balancesheet}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BALANCESHEETSUMMARY}
            component={BalanceSheetSummary}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BALANCESHEETDETAILS}
            component={BalanceSheetDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.SCHEDULES}
            component={Schedules}
            exact
          />
          <MainPrivateRoute path={ROUTES.CASHFLOW} component={CashFlow} exact />
          <MainPrivateRoute path={ROUTES.BUDGET} component={Budget} exact />
          <MainPrivateRoute
            path={ROUTES.BUDGETDETAILS}
            component={ViewBudgetDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.PURCHASEORDERDETAILS}
            component={ViewPurchaseOrderDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.COA}
            component={ChartsOfAccount}
            exact
          />
          <MainPrivateRoute path={ROUTES.INVOICES} component={Invoices} exact />
          <MainPrivateRoute path={ROUTES.TAXES} component={Taxes} exact />
          <MainPrivateRoute path={ROUTES.PRICING} component={Pricing} exact />
          <MainPrivateRoute path={ROUTES.BILLING} component={Billing} exact />
          <MainPrivateRoute path={ROUTES.STOCKS} component={Stocks} exact />
          <MainPrivateRoute
            path={ROUTES.STOCKOUTGOING}
            component={OutGoingStock}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.STOCKSUPPLIERS}
            component={Suppliers}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.STOCKPRODUCTS}
            component={Products}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.STOCKSETTINGS}
            component={StockSettings}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.STOCKBRANDS}
            component={Brands}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.STOCKCATEGORIES}
            component={StockCategories}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.GRANTAPPLICATION}
            component={Application}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.MEMBERSHIP}
            component={Membership}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VOLUNTEERS}
            component={Volunteers}
            exact
          />
          <MainPrivateRoute path={ROUTES.DONORS} component={Donors} exact />
          <MainPrivateRoute
            path={ROUTES.PROCUREMENT}
            component={Procurement}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.PURCHASEORDER}
            component={PurchaseOrder}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.BIDANALYSIS}
            component={BidAnalysis}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.QUOTATION}
            component={Quotation}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.GOODSRECIEVED}
            component={GoodsRecieved}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.GOODSRECIEVEDDETAILS}
            component={ViewGoodsReceivedDetails}
            exact
          />
          <MainPrivateRoute path={ROUTES.ASSETS} component={Assets} exact />
          <MainPrivateRoute path={ROUTES.ASSETSVIEW} component={View} exact />
          <MainPrivateRoute
            path={ROUTES.ASSETSTYPES}
            component={AssetTypes}
            exact
          />
          <MainPrivateRoute path={ROUTES.SETTINGS} component={Settings} exact />
          <MainPrivateRoute
            path={ROUTES.MYPROFILE}
            component={MyProfile}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ORGANISATION}
            component={OrganisationSettings}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.LOCATION}
            component={Locations}
            exact
          />
          <MainPrivateRoute path={ROUTES.ROLES} component={Roles} exact />
          <MainPrivateRoute
            path={ROUTES.PAYMENTTYPES}
            component={PaymentTypes}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.DEPARTMENTS}
            component={Departments}
            exact
          />
          <MainPrivateRoute path={ROUTES.USERS} component={Users} exact />
          <MainPrivateRoute
            path={ROUTES.ACTIVITYPARAMETERS}
            component={ActivityParameter}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.ACTIVITYTEAMROLES}
            component={ActivityTeamRoles}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.APPROVINGCOMMITTEE}
            component={ApprovingCommittee}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.REVIEWINGCOMMITTEE}
            component={ReviewingCommittee}
            exact
          />
          <MainPrivateRoute path={ROUTES.FUNDERS} component={Funders} exact />
          <MainPrivateRoute path={ROUTES.UNITS} component={Units} exact />
          <MainPrivateRoute
            path={ROUTES.ANNOUNCEMENTS}
            component={Announcements}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VIEWANNOUNCEMENT}
            component={ViewAnnouncement}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.VIEWTRANSACTIONDETAILS}
            component={TransactionDetails}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.COADETAILS}
            component={AccountDetails}
            exact
          />
          <MainPrivateRoute path={ROUTES.EQUITY} component={Equity} exact />
          <MainPrivateRoute
            path={ROUTES.SUBCATEGORIES}
            component={SubCategories}
            exact
          />
          <MainNonPrivateRoute path={ROUTES.LOGIN} component={Login} exact />
          <MainPrivateRoute
            path={ROUTES.CUSTOMERS}
            component={Customers}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.CUSTOMERREQUISITIONS}
            component={CustomerRequisitions}
            exact
          />
          <MainPrivateRoute
            path={ROUTES.RECEIVING}
            component={ReceivingReport}
            exact
          />
          <Route component={NotFound} />
        </Switch>
        <MoveToTop />
      </Router>
    </Suspense>
  );
}
