import React, { useEffect } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import usePayrolls from "../../../hooks/usePayrolls";
import { baseURLImage } from "../../../utils/axios";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";

const PayrollTable = () => {
  const { payrolls, loading } = usePayrolls();

  useEffect(()=> console.log(payrolls), [])

  const columnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Staff Name(s)",
      selector: (row) =>
        `${row?.staff?.user?.first_name} ${row?.staff?.user?.last_name}`,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Last Pay date",
      selector: (row) => row.pay_date,
      reorder: true,
      sortable: true,
    },
    {
      id: 3,
      name: "Last Gross Amount",
      selector: (row) => formatCurrency(row.net_pay),
      reorder: true,
      sortable: true,
    },
    {
      id: 4,
      name: "Last Total Deductions",
      selector: (row) => formatCurrency(row.deductions),
      reorder: true,
      sortable: true,
    },
    {
      id: 5,
      name: "Last Paid Amount",
      selector: (row) => formatCurrency(row.paid_amount),
      reorder: true,
      sortable: true,
    },
    {
      id: 6,
      name: "Last Payslip",
      selector: (row) => (
        <a
          target={"_blank"}
          rel="noreferrer"
          href={`${baseURLImage}/payrolls/${row.id}/print`}
          className="btn btn-sm btn-info"
        >
          <i className="fa fa-file-pdf-o"></i>
        </a>
      ),
      reorder: true,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className="row">
          <Link
            to={`/hr/payroll-management/view/${row.staff_id}`}
            className="btn btn-sm btn-primary mx-1"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
    },
  ];
  return (
    <div className="table-responsive mt-15">
      {loading ? (
        <EllipsisLoader />
      ) : (
        <MyDataTable data={payrolls} columns={columnData} title="" />
      )}
    </div>
  );
};

export default PayrollTable;
