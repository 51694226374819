import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { employeeAddressDefaultData } from "../../../../constants";
import useEmployees from "../../../../hooks/useEmployees";
import useGeoData from "../../../../hooks/useGeoData";
import {
  handleCountyChange,
  handleDistrictChange,
  handleParishChange,
  handleSubCountyChange,
} from "../../../../redux/reducer/geoData";
import Validator from "../../../../utils/Validator";
import Input from "../../../global/Input";
import InputSelect from "../../../global/InputSelect";
import { EllipsisLoader } from "../../../global/MyLoaders";
import {  formatSelectDataName } from "../../../../utils/helpers";
import { updateLocalStorage } from "./dataPersistenceHelper";

const Address = () => {
  const [address, setAddress] = useState(employeeAddressDefaultData);
  const { next, prev, storeEmployeeAddressDetails } = useEmployees();

  useEffect(()=>{
    const userData = localStorage.getItem('newUser');

    if(userData){
      const data = JSON.parse(userData)
      address.country.data = data.country
      address.district.data = data.district
      address.county.data = data.county
      address.sub_county.data = data.sub_county
      address.village.data = data.village
    }
  },[])

  const { counties, loading, parishes, subCounties, districts, countries } =
    useGeoData();

  const dispatch = useDispatch();



  const validator = new Validator();
  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box

    if (value !== "") {
      // update the state
      setAddress({
        ...address,
        ...{ [name]: { ...address[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setAddress({
        ...address,
        ...{ [name]: { ...address[name], error: true, data: value } },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    if (value !== "") {
      // update the state
      setAddress({
        ...address,
        ...{ [name]: { ...address[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setAddress({
        ...address,
        ...{ [name]: { ...address[name], error: true, data: value } },
      });
    }
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validator.checkForEmptyFields(address, setAddress)) {
      // continue
      const data = {
        country: address.country.data,
        district: address.district.data,
        county: address.county.data,
        sub_county: address.sub_county.data,
        village: address.village.data,
      };
      storeEmployeeAddressDetails(data);
      updateLocalStorage(data)
      next();
    }
  };
  
  return loading ? (
    <EllipsisLoader />
  ) : (
    <fieldset>
      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            label="Country"
            name="country"
            handleChange={(e: any) => {
              handleSelectChange(e);
              // dispatch(handleDistrictChange(e));
            }}
            value={{label:address.country.data, value:address.country.data}}
            error={address.country.error}
            errorMessage="Please select the country"
            required
            data={formatSelectDataName(countries)}
            id="county"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            label="District"
            name="district"
            handleChange={(e: any) => {
              handleSelectChange(e);
              dispatch(handleDistrictChange(e));
            }}
            value={{label:address.district.data, value: address.district.data}}
            error={address.district.error}
            errorMessage="Please select the district"
            required
            data={districts}
            id="district"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            label="County"
            name="county"
            handleChange={(e: any) => {
              handleSelectChange(e);
              dispatch(handleCountyChange(e));
            }}
            value={{label:address.county.data, value: address.county.data}}
            error={address.county.error}
            errorMessage="Please select the district"
            required
            data={counties}
            id="county"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={subCounties}
            id="sub_county"
            label="Sub County"
            name="sub_county"
            handleChange={(e: any) => {
              handleSelectChange(e);
              dispatch(handleSubCountyChange(e));
            }}
            value={{label:address.sub_county.data, value: address.sub_county.data}}
            error={address.sub_county.error}
            errorMessage="Please provide the sub county"
            required
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={parishes}
            id="parish"
            label="Parish"
            name="parish"
            handleChange={(e: any) => {
              handleSelectChange(e);
              dispatch(handleParishChange(e));
            }}
            value={{label:address.sub_county.data, value: address.sub_county.data}}
            error={address.sub_county.error}
            errorMessage="Please provide the parish"
            required
          />
        </div>
        <div className="col-sm-4">
          <Input
            label="Village"
            name="village"
            handleChange={handleChange}
            value={address.village.data}
            error={address.village.error}
            errorMessage="Please provide the village"
            required
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="button"
          className="btn btn-outline-danger mr-3"
          onClick={prev}
        >
          Prev
        </button>
        <button type="button" className="btn  btn-info" onClick={handleNext}>
          Next
        </button>
      </div>
    </fieldset>
  );
};

export default Address;
