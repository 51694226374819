import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from "react-router-dom";
import useBidStages from '../../../../hooks/useBidStages';
import MyDataTable from '../../../global/MyDataTable';
import { EllipsisLoader } from '../../../global/MyLoaders';
import ModalSwitcher from '../../../global/ModalSwitcher';
import AddStage from './AddStage';
import ReOrderBidStage from './ReOrderStages';
import ConfirmDelete from '../../../global/ConfirmDelete';
import ViewStage from './ViewStage';
import { formatDate } from '../../../../utils/helpers';

const ListBidStages = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const {bidStage, getBidStages, loading, bidStages, refresh} = useBidStages()

  useEffect(()=> refresh(), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Stage Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "Order No.",
      selector: (row) => row.order_no,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 4,
      name: "Added on",
      selector: (row) => formatDate(row.created_at),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, 
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <button className='btn btn-sm btn-info mx-1' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}><i className='fa fa-eye'></i></button>
          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-bid-stage';
      modalTitle = 'Edit Bid Stage';
      ModalToRender = ViewStage
    break;
    case 3:
      modalId = 'delete-bid-stage';
      modalTitle = 'Delete Bid Stage';
      ModalToRender = ConfirmDelete
      break;
      case 4:
        modalId = 're-order-bid-stages';
        modalTitle = 'Re-order Bid Stages';
        ModalToRender = ReOrderBidStage
        break;
    default:
      modalId = 'add-new-bid-stage';
      modalTitle = 'Add New Bid Stage';
      ModalToRender = AddStage
      break;
  }

  return loading ? <EllipsisLoader /> : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`bid_stages/${selectedId}`} refresh={getBidStages} onCancel={handleModalClick}/>} />
        
        <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
            <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(0);
                    }}
                  >
                    <i className="fa fa-plus mr-1"></i>Add New Bid Stage
                  </button>
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(4);
                    }}
                  >
                    <i className="fa fa-arrow-circle-up"></i>
                    <i className="fa fa-arrow-circle-down ml-2 mr-2"></i>
                    Re-order Bid Stages
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={bidStages} title="" />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListBidStages