import React from "react";
import ListRequisitions from "../../components/procurement/requisition/ListRequisitions";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import RequisitionTabs from "../../components/procurement/requisition/RequisitionTabs";

const Requisition = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Purchase Requests" />
        {/* body */}
        {/* <ListRequisitions /> */}
        <RequisitionTabs />
      </>
    </DashboardWrapper>
  );
};

export default Requisition;
