import React from 'react'
import ListProjectCategories from './ListProjectCategories'
// import Stats from './Stats'

const ProjectsCategoriesDashboard = () => {
  return (
    <>
      {/* <Stats /> */}
      <ListProjectCategories />
    </>
  )
}

export default ProjectsCategoriesDashboard