import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import ListBidStages from '../../components/procurement/bidAnalysis/bid_stages/ListStage'

const BidStages = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Bid Stages' />
        {/* body */}
        <ListBidStages />
      </>
    </DashboardWrapper>
  )
}

export default BidStages