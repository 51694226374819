
import { createSlice } from '@reduxjs/toolkit';

export interface IHr {
  schedules: any;
  selectedSchedule: any;
  timeSheets: any;
  selectedTimeSheets: any;
  employees: any;
  monthlyActivities: any;
  selectedMonthlyActivity: any;
  leaves: any;
  selectedLeave: any;
  selectedEmployee: any;
  payrolls: any;
  selectedPayroll: any;
  selectedStaffPayroll: any;
  selectedStaffPayrollStats: any;
  payrollStats: any;
  staffPerformance: any;
  payrollItems: any;
  payrollDeducationItems: any;
  totalPay: number;
  totalDeducation: number;
  netPay: number;
  inputName: string;
  addEmployeeFormId: number;
  addEmployeeEducationDetails: Array<any>;
  addEmployeeEmploymentDetails: Array<any>;
  addEmployeeRefereeDetails: Array<any>;
  addEmployeeOtherTrainingDetails: Array<any>;
  employeePersonalDetails: any;
  employeeAddressDetails: any;
  formSelectEmployeeData: any;
  leaveTypes: any;
  generatedPayroll: any;
}

const initialState: IHr = {
  timeSheets: [],
  selectedTimeSheets: {},
  schedules: [],
  selectedSchedule: {},
  monthlyActivities: [],
  selectedMonthlyActivity: {},
  employees: [],
  leaves: {},
  selectedLeave: {},
  selectedEmployee: {},
  payrolls: {},
  selectedPayroll: {},
  staffPerformance: {},
  selectedStaffPayroll: [],
  formSelectEmployeeData: [],
  selectedStaffPayrollStats: {},
  payrollStats: {},
  totalPay: 0,
  netPay: 0,
  totalDeducation: 0,
  inputName: '',
  addEmployeeFormId: 1,
  addEmployeeEducationDetails: [],
  addEmployeeEmploymentDetails: [],
  addEmployeeRefereeDetails: [],
  addEmployeeOtherTrainingDetails: [],
  employeePersonalDetails: {},
  employeeAddressDetails: {},
  leaveTypes: [],
  generatedPayroll: [],

  payrollItems: [
    {
      id: 1,
      name: "Basic Pay",
      slug: "basic_pay"
    },
    {
      id: 2,
      name: "Commision",
      slug: "commision"
    },
  ],
  payrollDeducationItems: [
    {
      id: 1,
      name: "Total deductions",
      slug: "total_deductions"
    },
  ],
};

export const hrReducer = createSlice({
  name: 'hr',
  initialState,
  reducers: {
    setMonthlyActivities: (state, {payload}) => {
      state.monthlyActivities = payload;
    },
    setSelectedMonthlyActivity: (state, { payload }) => {
      state.selectedMonthlyActivity = payload;
    },
    setSchedules: (state, {payload}) => {
      state.schedules = payload;
    },
    setSelectedSchedule: (state, { payload }) => {
      state.selectedSchedule = payload;
    },
    setTimeSheets: (state, {payload}) => {
      state.timeSheets = payload;
    },
    setSelectedTimeSheet: (state, { payload }) => {
      state.selectedTimeSheets = payload;
    },
    setEmployees: (state, { payload }) => {
      state.employees = payload;
    },
    setEmployeeLeave: (state, { payload }) => {
      state.leaves = payload;
    },
    setSelectedLeave: (state, { payload }) => {
      state.selectedLeave = payload;
    },
    setSelectedEmployee: (state, { payload }) => {
      state.selectedEmployee = payload;
    },
    setPayrolls: (state, { payload }) => {
      state.payrolls = payload
    },
    setSelectedPayroll: (state, { payload }) => {
      state.selectedPayroll = payload
    },
    setStaffPerformance: (state, { payload }) => {
      state.staffPerformance = payload
    },
    setPayrollItems: (state, { payload }) => {
      state.payrollItems = payload
    },
    setPayrollDeducationItems: (state, { payload }) => {
      state.payrollDeducationItems = payload
    },
    setTotalPay: (state, { payload }) => {
      const { value } = payload;

      const newTotalPay = state.totalPay += Number(value);
      console.log(newTotalPay);
      state.totalPay = newTotalPay;
      state.netPay = newTotalPay - state.totalDeducation;
    },
    setTotalDeducation: (state, { payload }) => {
      const { value } = payload;
      const newTotalDeducation = state.totalDeducation += Number(value);
      state.totalDeducation = newTotalDeducation;
      state.netPay = state.totalPay - newTotalDeducation;
    },

    clearItemsData: (state) => {
      state.netPay = 0;
      state.totalDeducation = 0;
      state.totalPay = 0;
    },
    setSelectedStaffPayroll: (state, { payload }) => {
      state.selectedStaffPayroll = payload
    },
    setSelectedPayrollStats: (state, { payload }) => {
      state.selectedStaffPayrollStats = payload
    },
    moveForward: (state) => {
      if (state.addEmployeeFormId < 8) {
        state.addEmployeeFormId += 1
      }
    },
    moveBackward: (state) => {
      if (state.addEmployeeFormId > 1) {
        state.addEmployeeFormId -= 1;
      }
    },

    setEmployeeEducationDetails: (state, { payload }) => {
      state.addEmployeeEducationDetails.push(payload);
    },
    setEmployeeEmploymentDetails: (state, { payload }) => {
      state.addEmployeeEmploymentDetails.push(payload);
    },
    setEmployeeRefereeDetails: (state, { payload }) => {
      state.addEmployeeRefereeDetails.push(payload);
    },
    setEmployeeOtherTrainingDetails: (state, { payload }) => {
      state.addEmployeeOtherTrainingDetails.push(payload);
    },
    setEmployeePersonalDetails: (state, { payload }) => {
      state.employeePersonalDetails = payload;
    },
    seEmployeeAddressDetails: (state, { payload }) => {
      state.employeeAddressDetails = payload;
    },
    resetFormData: (state) => {
      state.addEmployeeEducationDetails = [];
      state.addEmployeeEmploymentDetails = [];
      state.addEmployeeRefereeDetails = [];
      state.addEmployeeOtherTrainingDetails = [];
      state.employeePersonalDetails = {};
      state.employeeAddressDetails = {};
      state.addEmployeeFormId = 1;
    },
    setFormSelectEmployeeData: (state, { payload }) => {
      state.formSelectEmployeeData = payload;
    },
    resetPayroll: (state) => {
      state.totalPay = 0;
      state.netPay = 0;
      state.totalDeducation = 0;
    },

    setPayrollStats: (state, { payload }) => {
      state.payrollStats = payload;
    },

    setLeaveTypes: (state, { payload }) => {
      state.leaveTypes = payload;
    },

    setGeneratedPayroll: (state, { payload }) => {
      state.generatedPayroll = payload;
    }
  },
});

const { reducer, actions } = hrReducer;

export const {
  setTimeSheets, setSelectedTimeSheet, setSchedules, setSelectedSchedule, setSelectedMonthlyActivity, setMonthlyActivities, setEmployees, setEmployeeLeave, setSelectedLeave, setSelectedEmployee, setPayrolls, setSelectedPayroll, setStaffPerformance, setPayrollItems, setPayrollDeducationItems, setTotalDeducation, setTotalPay, clearItemsData, setSelectedStaffPayroll, setSelectedPayrollStats, moveBackward, moveForward, setEmployeeEducationDetails, setEmployeeEmploymentDetails, setEmployeeRefereeDetails, setEmployeeOtherTrainingDetails, setEmployeePersonalDetails, seEmployeeAddressDetails, resetFormData, setFormSelectEmployeeData, resetPayroll, setPayrollStats, setLeaveTypes, setGeneratedPayroll
} = actions;

export default reducer;