import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'
import IncomeStatementComparison from '../../components/accounting/incomeStatement/ISComparisonReport'

const ViewIncomeStatementComparisonReport = () => {
  return (
    <DashboardWrapper >
      <>
        <Breadcrumbs title='Income Statement Comparison Report' parents={[{ link: ROUTES.REPORTS, title: 'Reports' }]} />
        <IncomeStatementComparison />
      </>
    </DashboardWrapper >
  )
}

export default ViewIncomeStatementComparisonReport