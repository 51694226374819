import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import {
  setAccounts,
  setAssetAccount,
  setBalanceSheet,
  setBalanceSheetLialibilty,
  setExpenseAccount,
  setAccumulatedDepreciationAccounts,

  setCashAccount,
  setFormattedAssetAccounts,
  setFormattedExpenseAccounts,

  setLiabilityAccount,
  setFormattedLiabilityAccounts,

  setFormattedAccumulatedDepreciationAccounts,
  setFormattedExpenseType,
  setFormattedIncomeAccounts,
  setFormattedIncomeType,
  setIncomeAccount,
  setIncomeStatement,
  setIncomeStatementExpense,
  setSelectedAccount,
  setSelectedAccountDetails,
  setSelectedTransaction,
  setTrailBalance,
  setTransactions,
} from "../redux/reducer/accounting";
import {
  formatAcountSelectData,
  formatDepartmentSelectData,
} from "../utils/helpers";

const useAccounts = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { reportStartDate, reportEndDate } = useSelector(
    (state: RootState) => state.general
  );

  const {
    accounts,
    incomeAccounts,
    formattedIncomeAccounts,
    assetAccounts,
    liabilityAccounts,
    expenseAccounts,
    formattedAssetAccounts,
    formattedLiabilityAccounts,
    formattedExpenseAccounts,
    formattedAccumulatedDepreciationAccounts,
    formattedIncomeTypes,
    formattedExpenseTypes,
    transactions,
    incomeStatement,
    balanceSheet,
    balanceSheetLialibility,
    incomeStatementExpense,
    selectedAccount,
    cashAccounts,
    selectedTransaction,
    selectedAccountDetails,
    trailBalance,
  } = useSelector((state: RootState) => state.accounting);

  useEffect(() => {
    if (accounts.length > 0) {
      setLoading(false);
      return;
    }

    doGet("account-charts")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setAccounts(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountName = (id: number) => {
    const filtered = accounts.filter((data: any) => data.id === id);

    if (filtered.length === 1) {
      return filtered[0]["account_name"];
    }
    return "";
  };

  const refresh = () => {
    doGet("account-charts")
      .then((response) => {
        if (response.status === 200) {
          
          dispatch(setAccounts(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIncomeAccount = () => {
    if (incomeAccounts.length > 0) {
      return;
    }
    if (accounts.length > 0) {
      dispatch(
        setIncomeAccount(
          accounts.filter(
            (account: any) =>
              (account: any) => account?.account_sub_category?.account_category_id === 4
              //account?.account_sub_category_id === 13
          )
        )
      );
      dispatch(
        setFormattedIncomeAccounts(
          formatAcountSelectData(
            accounts.filter(
              (account: any) =>
                (account: any) => account?.account_sub_category?.account_category_id === 4
                //account?.account_sub_category_id === 12 ||
                //account?.account_sub_category_id === 13
            )
          )
        )
      );
    }
  };

  const getExpenseAccount = () => {
    if (accounts.length > 0) {
      dispatch(
        setExpenseAccount(
          accounts.filter(
            (account: any) => account?.account_sub_category?.account_category_id === 5
              //account?.account_sub_category_id === 15 ||
              //account?.account_sub_category_id === 16
          )
        )
      );

      dispatch(
        setFormattedExpenseAccounts(
          formatAcountSelectData(
            accounts.filter(
              (account: any) => account?.account_sub_category?.account_category_id === 5
                //account?.account_sub_category_id === 15 ||
                //account?.account_sub_category_id === 16
            )
          )
        )
      );
    }
  };
  const getAccumulatedDepreciationAccount = () => {
    if (accounts.length > 0) {
      dispatch(
        setAccumulatedDepreciationAccounts(
          accounts.filter(
            (account: any) => account?.account_sub_category?.account_category_id === 5
              //account?.account_sub_category_id === 15 ||
              //account?.account_sub_category_id === 16
          )
        )
      );

      dispatch(
        setFormattedAccumulatedDepreciationAccounts(
          formatAcountSelectData(
            accounts.filter(
              (account: any) =>
                account?.account_sub_category_id === 24 
                //account?.account_sub_category_id === 16
            )
          )
        )
      );
    }
  };

  const getCashAccount = () => {
    if (accounts.length > 0) {
      dispatch(
        setCashAccount(
          accounts.filter(
            (account: any) =>
              account?.account_sub_category_id === 4 ||
              account?.account_sub_category_id === 3
          )
        )
      );
    }
    setLoading(false);
  };

  const getLiabilityAccount = () => {
    
    if (accounts.length > 0) {

      dispatch(
        setLiabilityAccount(
          accounts.filter(
            (account: any) =>  account?.account_sub_category?.account_category_id === 3
           
          )
        )
      );
      dispatch(
        setFormattedLiabilityAccounts(
          formatAcountSelectData(
            accounts.filter(
              (account: any) => account?.account_sub_category?.account_category_id === 3
                //account?.account_sub_category_id === 6 ||
              //account?.account_sub_category_id === 7
              
            )
          )
        )
      );
    }
    setLoading(false);
  };
  const getAssetAccount = () => {
    
    if (accounts.length > 0) {

      dispatch(
        setAssetAccount(
          accounts.filter(
            (account: any) =>  account?.account_sub_category?.account_category_id === 1
           
          )
        )
      );
      dispatch(
        setFormattedAssetAccounts(
          formatAcountSelectData(
            accounts.filter(
              (account: any) => account?.account_sub_category?.account_category_id === 1
                //account?.account_sub_category_id === 6 ||
              //account?.account_sub_category_id === 7
              
            )
          )
        )
      );
    }
    setLoading(false);
  };

  const getAssetIncomeTypes = () => {
    if (formattedIncomeTypes.length > 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    doGet("get-asset-income-types")
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setFormattedIncomeType(
              formatDepartmentSelectData(response.data.data)
            )
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAssetExpenseTypes = () => {
    if (formattedExpenseTypes.length > 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    doGet("get-asset-expense-types")
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setFormattedExpenseType(
              formatDepartmentSelectData(response.data.data)
            )
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTransactions = () => {
    if (transactions.length > 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    doGet("transactions")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setTransactions(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshTransactions = () => {
    setLoading(true);
    doGet("transactions")
      .then((response) => {
        if (response.status === 200) {
          dispatch(setTransactions(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTrailBalance = () => {
    setLoading(true);
    doGet(
      `trail_balance?start_date=${reportStartDate}&end_date=${reportEndDate}`
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(setTrailBalance(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIncomeStatement = () => {
    // if (incomeStatement.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    setLoading(true);
    doGet(
      `balancesheet/4?start_date=${reportStartDate}&end_date=${reportEndDate}`
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(setIncomeStatement(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    doGet(
      `balancesheet/5?start_date=${reportStartDate}&end_date=${reportEndDate}`
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(setIncomeStatementExpense(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBalancesheet = () => {
    // if (balanceSheet.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    setLoading(true);
    doGet(
      `balancesheet/1?start_date=${reportStartDate}&end_date=${reportEndDate}`
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(setBalanceSheet(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    doGet(
      `balancesheet/2?start_date=${reportStartDate}&end_date=${reportEndDate}`
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(setBalanceSheetLialibilty(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccount = () => {
    getIncomeAccount();
    getExpenseAccount();
    getAccumulatedDepreciationAccount();
    getAssetAccount();
  };

  const getAccountNameById = (id: number) => {
    if (accounts) {
      const account = accounts.filter((account: any) => account?.id === id);
      if (account.length > 0) {
        return account[0].account_code + " " + account[0].account_name;
      }
    }

    return "";
  };

  const getSelectedAccount = (id: number) => {
    setLoading(true);
    doGet(`account-charts/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedAccount(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTransactionDetails = (id: number) => {
    setLoading(true);
    doGet(`transactions/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedTransaction(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccountTransactionDetails = (
    id: number,
    start_date = null,
    end_date = null
  ) => {
    setLoading(true);
    let url = `account-charts/${id}/transactions`;
    if (start_date && end_date) {
      url = `account-charts/${id}/transactions?start_date=${start_date}&end_date=${end_date}`;
    }
    doGet(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedAccountDetails(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAccountTransactionDetailsOptions = (
    id: number,
    page = 1,
    perPage = 10
  ) => {
    doGet(`account-charts/${id}/transactions?page=${page}&per_page=${perPage}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedAccountDetails(response.data.data));
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  // const searchTransactions = (searchParam: any) => {

  // }

  return {
    accounts,
    loading,
    refresh,
    getIncomeAccount,
    incomeAccounts,
    formattedIncomeAccounts,
    getAssetAccount,
    assetAccounts,
    formattedAssetAccounts,

    getLiabilityAccount,
    liabilityAccounts,
    formattedLiabilityAccounts,

    getAccumulatedDepreciationAccount,
    getExpenseAccount,
    expenseAccounts,
    formattedAccumulatedDepreciationAccounts,
    formattedExpenseAccounts,
    getAccount,
    getAssetIncomeTypes,
    formattedIncomeTypes,
    getAssetExpenseTypes,
    formattedExpenseTypes,
    getAccountName,
    getTransactions,
    transactions,
    incomeStatement,
    getIncomeStatement,
    balanceSheet,
    getBalancesheet,
    balanceSheetLialibility,
    incomeStatementExpense,
    getSelectedAccount,
    selectedAccount,
    cashAccounts,
    getCashAccount,
    refreshTransactions,
    getAccountNameById,
    getTransactionDetails,
    selectedTransaction,
    selectedAccountDetails,
    getAccountTransactionDetails,
    getAccountTransactionDetailsOptions,
    reportStartDate,
    reportEndDate,
    getTrailBalance,
    trailBalance,
  };
};

export default useAccounts;
