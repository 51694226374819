import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { errorToastOptions } from "../../constants";
import useUnits from "../../hooks/useUnits";
import { formatSelectedRequisitionData } from "../../utils/helpers";
import Input from "./Input";
import InputSelect from "./InputSelect";
import { EllipsisLoader } from "./MyLoaders";
import Textarea from "./Textarea";
import useRequisition from "../../hooks/useRequisition";
import useStockProduct from "../../hooks/useStockProducts";

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>;
  requisitionItems?: item[] | any [];
}

interface item {
  value: string | number;
  label: string
}

const QuotationDynamicForm = ({ defaultData, values, setValues, requisitionItems }: IDynamicFormProps) => {
  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  };

  const handleMinusClick = (index: number) => {
    const newState = values.slice(); // Create a shallow copy of the values array
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    }
    newState.splice(index, 1);
    setValues([...newState]);
  };

  useEffect(()=>console.log('aqa', requisitionItems), [requisitionItems])

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = [...values]; // Create a copy of the values array
    newState[Number(id)][name] = value;

    if (name === "quantity" || name === "rate") {
      const quantity = parseFloat(newState[Number(id)]["quantity"] || 0);
      const price = parseFloat(newState[Number(id)]["rate"] || 0);
      newState[Number(id)]["total"] = (quantity * price).toFixed(2);
    }

    setValues(newState);
  };

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e;
    const selectedProduct = stockProducts.find(
      (product:any) => product.id === parseInt(value)
    );
    const newState = [...values]; // Create a copy of the values array
    newState[Number(id)][name] = value;
    newState[Number(id)]["rate"] = selectedProduct
      ? selectedProduct.price.toString()
      : ""; // Set the rate based on the selected product
    setValues(newState);
  };

  const { stockProducts, loading } = useStockProduct();

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div className="col-sm-12">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {values &&
            values.length > 0 &&
            values.map((item, index) => (
              <tr key={index}>
                <td width={"30%"}>
                 {requisitionItems && 
                   <InputSelect
                   label=""
                   handleChange={(e) => handleSelectChange(e, index)}
                   id={index}
                   name={`item_id`}
                   data={formatSelectedRequisitionData(requisitionItems)}
                   withNoLabel
                   value={values[index].stockProducts}
                 />
                }
               </td>
                <td>
                  <Input
                    label=""
                    handleChange={handleChange}
                    id={index}
                    name={`quantity`}
                    noLabel
                  />
                </td>
                <td>
                  <div className="form-row flex-nowrap">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => handleMinusClick(index)}
                      >
                        -
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() => handleAddClick()}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuotationDynamicForm;
