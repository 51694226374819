import React, { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import Textarea from "../../global/Textarea";
import useRequisition from "../../../hooks/useRequisition";
import { EllipsisLoader } from "../../global/MyLoaders";

interface IAcceptRequisitionProps {
  onCancel: () => void;
  refresh: () => void;
  id?: any;
}

const AcceptProposal = ({
  onCancel,
  refresh,
  id,
}: IAcceptRequisitionProps) => {
  const [comment, setComment] = useState({ error: false, data: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { getRequisition, requisition, loading } = useRequisition();

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (id) {
      getRequisition(id)
    }
  }, []);

  useEffect(()=>{
    setTimeout(()=>{
        setSelectedItems( requisition?.items?.map((item: any) => ({
          ...item,
          selected: true,
          quantity: item.quantity,
        })) || [])
        setIsLoadingData(false)
    }, 500)
  },[requisition])

  // useEffect(()=>{console.log('sels', selectedItems
  // )},[selectedItems])

  const handleCheckboxChange = useCallback((itemId: number) => {
    setSelectedItems((prevItems: any) =>
      prevItems.map((item: any) =>
        item.id === itemId ? { ...item, selected: !item.selected } : item
      )
    );
  }, []);

  const handleQuantityChange = useCallback(
    (itemId: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSelectedItems((prevItems: any) =>
        prevItems.map((item: any) =>
          item.id === itemId ? { ...item, quantity: value } : item
        )
      );
    },
    []
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const selectedItemsData = selectedItems.filter(
      (item: any) => item.selected
    );
    
    const selectedReqItems = selectedItemsData.map((item: any) => ({
      id: item.id,
      quantity: item.quantity,
    }));
    console.log(selectedReqItems);
    if (selectedReqItems.length !== 0) {
      if (comment.data.length > 2) {
        const data = {
          comment: comment.data,
          status: "Approved",
          requisition_id: requisition.id,
          items: selectedReqItems,
        };

        setIsLoading(true);

        doCreate("process_purchase_requisitions", data)
          .then((response) => {
            if (response.data.success === true) {
              toast.success(
                "Your requisition successfully accepted",
                successToastOptions
              );
              refresh();

              setTimeout(() => {
                onCancel();
              }, 1000);
            } else {
              toast.error(
                formatErrorMessage(response.data.data, response.data.message),
                errorToastOptions
              );
            }
          })
          .catch(() => {
            toast.error(ERRORMESSAGE, errorToastOptions);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setComment({ error: true, data: comment.data });
        return;
      }
    } else {
      toast.error(
        "Please select atleast one item to approve requisition ",
        errorToastOptions
      );
      return;
    }
  };

  return isLoadingData ? (
    <EllipsisLoader />
  ) : (
    <form onSubmit={handleSubmit}>
      {selectedItems?.map((item: any) => (
        <div key={item.id} className="form-row mb-4">
          <div className="col-lg-2">
            <label className="form-label"></label>
            <input
              className="form-control mt-4"
              type="checkbox"
              value={item.id}
              checked={item.selected}
              onChange={() => handleCheckboxChange(item.id)}
            />
          </div>
          <div className="col-lg-5">
            <label className="form-label">Item Name</label>
            <input className="form-control" value={item.item?.name} disabled/>
          </div>
          <div className="col-lg-3">
            <label className="form-label">Quantity</label>
            <input
              type="number"
              className="form-control"
              value={item.quantity}
              onChange={(e) => handleQuantityChange(item.id, e)}
            />
          </div>
         
        </div>
      ))}

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            withNoLabel
            name="comment"
            label="Comment"
            handleChange={(e) =>
              setComment({ error: false, data: e.target.value })
            }
            required
            errorMessage="Please provide a comment"
            value={comment.data}
            error={comment.error}
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="button"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" disabled={isLoading} className="btn  btn-info">
          {isLoading ? "Processing..." : "Accept"}
        </button>
      </div>
    </form>
  );
};

export default AcceptProposal
