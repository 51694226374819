import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { createNewProjectCategory } from '../../../api/projects';
import { categoryDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useProjects from '../../../hooks/useProjects';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';

interface ICreateProjectProps {
  closeModal: () => void;
}

const AddProjectCategory = ({ closeModal: onCancel }: ICreateProjectProps) => {
  const [projectCategory, setProjectCategory] = useState(categoryDefaultData);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();

  const { refresh } = useProjects();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProjectCategory({ ...projectCategory, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setProjectCategory({ ...projectCategory, ...{ [name]: { error: true, data: value } } });
    }
  };


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(projectCategory, setProjectCategory)) {
      setLoading(true);

      const data = {
        name: projectCategory.name.data,
        description: projectCategory.description.data,
      }

      // console.log(data); return;

      try {
        const newProjectCategory = await createNewProjectCategory(data);
        if (newProjectCategory.data.success === true) {
          toast.success('New project category saved successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProjectCategory.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Project Category Name' handleChange={handleChange} value={projectCategory.name.data} error={projectCategory.name.error} errorMessage="Please provide the project category name" required />
        </div>
        <div className="col-sm-6">
          <Input name='description' label='Description' handleChange={handleChange} value={projectCategory.description.data} error={projectCategory.description.error} errorMessage="Please provide the project category description" required />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default AddProjectCategory