import React, { useEffect, useState } from 'react'
import useEmployeeLeaves from '../../../hooks/useEmployeeLeaves';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import { EllipsisLoader } from '../../global/MyLoaders';
import { formatState } from '../../../utils/helpers';
import useSessionStorage from '../../../hooks/useSessionStorage';
import Review from './Review';
import ModalSwitcher from '../../global/ModalSwitcher';
import ConfirmDelete from '../../global/ConfirmDelete';
import Approve from './Approve';
import Decline from './Decline';
import ConfirmAction from '../../global/ConfirmAction';

const ViewLeaveDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [steps, setSteps] = useState(1);
  const id = useLastUrlSegment();
  const { selectedLeave, getSelectedLeave, loading } = useEmployeeLeaves();
  const { isAdmin } = useSessionStorage();
  useEffect(() => {
    console.log('lol', getSelectedLeave(id))
    return getSelectedLeave(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const selectedId = useLastUrlSegment();

  let modalId;
  let modalTitle;
  let ModalToRender;
  switch (steps) {
    case 2:
      modalId = 'add-review';
      modalTitle = 'Add a review comment';
      ModalToRender = Review
      break;
    case 3:
      modalId = 'approve';
      modalTitle = 'Approve the leave request';
      ModalToRender = Approve
      break;
    case 4:
      modalId = 'decline';
      modalTitle = 'Decline the leave request';
      ModalToRender = Decline
      break;

    case 5:
      modalId = 'delete-leave';
      modalTitle = 'Delete leave request';
      ModalToRender = ConfirmDelete
      break;
    case 6:
      modalId = 'delete-leave';
      modalTitle = 'Do you want to mark leave as completed?';
      ModalToRender = ConfirmAction
      break;
    default:
      modalId = 'add-review';
      modalTitle = 'Add a review comment';
      ModalToRender = Review
      break;
  }


  return loading ? (<EllipsisLoader />) : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`leave/${selectedId}/delete`} refresh={() => getSelectedLeave(id)} url={`leave/${selectedId}/complete`} />} />

      <div className='row'>
        <div className='col-xl-8 mb-30'>
          <Card>
            <>
              <CardHeaderWithBottomBorder title={selectedLeave?.leave_type} categories={[{ title: formatState(selectedLeave?.state_id).title, className: formatState(selectedLeave?.state_id).className }]} />
              <CardContentWrapper>
                <div className='row'>

                  <div className="col-sm-12">
                    <h6>Staff names</h6>
                    <p>{`${selectedLeave?.staff.user.first_name} ${selectedLeave?.staff.user.last_name}`}</p>
                  </div>

                  <div className="col-sm-6">
                    <h6>Comment</h6>
                    <p>{selectedLeave?.comment}</p>
                  </div>

                  <div className="col-sm-4">
                    <h6>Applied on</h6>
                    <p>{selectedLeave?.date_of_application}</p>
                  </div>
                  <div className="col-sm-4">
                    <h6>Start date</h6>
                    <p>{selectedLeave?.start_date}</p>
                  </div>

                </div>
              </CardContentWrapper>

            </>
          </Card>
        </div>

        <div className='col-xl-4 mb-30'>
          <Card>
            <>
              <CardHeaderWithBottomBorder title="Actions" />
              {isAdmin() && (<CardContentWrapper>
                <>
                  <div className='row mx-0'>
                    {selectedLeave?.state_id === 1 && (
                      <>
                        <div className="col-xs-6 mr-3">
                          <button className='btn btn-sm btn-primary' onClick={() => {
                            setSteps(2);
                            setIsOpen(true);
                          }}> <i className='fa fa-calendar-plus-o'></i> Review</button>
                        </div>
                        <div className="col-xs-6">
                          <button className='btn btn-sm btn-danger' onClick={() => {
                            setSteps(4);
                            setIsOpen(true);
                          }}> <i className='fa fa-calendar-times-o'></i> Decline</button>
                        </div>
                      </>
                    )}

                    {selectedLeave?.state_id === 2 && (
                      <>
                        <div className="col-xs-6 mr-3">
                          <button className='btn btn-sm btn-primary' onClick={() => {
                            setSteps(3);
                            setIsOpen(true);
                          }}> <i className='fa fa-calendar-plus-o'></i> Approve</button>
                        </div>
                        <div className="col-xs-6">
                          <button className='btn btn-sm btn-danger' onClick={() => {
                            setSteps(4);
                            setIsOpen(true);
                          }}> <i className='fa fa-calendar-times-o'></i> Decline</button>
                        </div>
                      </>
                    )}


                    <div className="col-xs-6 ml-3">
                      {selectedLeave?.state_id === 3 && (<button className='btn btn-sm btn-info' onClick={() => {
                        setSteps(6);
                        setIsOpen(true);
                      }}> <i className='fa fa-calendar-check-o'></i> Complete</button>)}
                    </div>
                  </div>

                </>

              </CardContentWrapper>
              )}

            </>
          </Card>

        </div>

      </div>
    </>
  )
}

export default ViewLeaveDetails