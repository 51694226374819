import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import ProjectsCategoriesDashboard from '../../../components/project/dashboard/ProjectCategories'


const ProjectCategories = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Project Categories' />
        <ProjectsCategoriesDashboard />
      </>
    </DashboardWrapper>
  )
}

export default ProjectCategories