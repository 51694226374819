import React from 'react'

interface IStepsProps {
  id: number;
}

const Steps = ({ id }: IStepsProps) => {
  return (
    <ul id="progressbar">
      <li className={`${id >= 1 && "active"}`} id="account"><strong>Bio-data</strong></li>
      <li className={`${id >= 2 && "active"}`} id="address"><strong>Address</strong></li>
      <li className={`${id >= 3 && "active"}`} id="education"><strong>Education</strong></li>
      <li className={`${id >= 4 && "active"}`} id="work"><strong>Employment</strong></li>
      <li className={`${id >= 5 && "active"}`} id="referees"><strong>Referees</strong></li>
      <li className={`${id >= 6 && "active"}`} id="other"><strong>Other training</strong></li>
      <li className={`${id >= 7 && "active"}`} id="other"><strong>Other Important Info</strong></li>
      <li className={`${id >= 8 && "active"}`} id="confirm"><strong>Submit</strong></li>
    </ul>
  )
}

export default Steps