export function updateLocalStorage(newData: {}) {
    const userData = localStorage.getItem('newUser');
    if(!userData){
        localStorage.setItem("newUser", JSON.stringify(newData))
    }
    
    let updatedData = {};

    if (userData) {
        const existingData = JSON.parse(userData);
        updatedData = {
            ...existingData,
            ...newData
        };
    } else {
        updatedData = newData;
    }

    localStorage.setItem('newUser', JSON.stringify(updatedData));
}

export function selectDataPersistenceHelper (options: any[], id: any) {
    const option = options.find((option: { value: any; }) => option.value === id);
    return option ? option.label : '';
}

