import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import { setIncomeStatement, setISComparisonReport } from "../redux/reducer/accounting";

const useIncomeStatement = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { incomeStatement } = useSelector(
    (state: RootState) => state.accounting
  );

  const getIncomeStatement = () => {
    doGet(`/incomestatement`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setIncomeStatement(response.data.data));
        }
      })
      .catch((err) => {console.log('error', err)})
      .finally(() => {
        setLoading(false);
      });
  };

  const getIncomeStatementComparisonReport = () => {
    doGet(`/comparison-income-statement`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setISComparisonReport(response.data.data));
        }
      })
      .catch((err) => {console.log('error', err)})
      .finally(() => {
        setLoading(false);
      });
  };


  return {
    incomeStatement,
    loading,
    getIncomeStatement,
    getIncomeStatementComparisonReport
  };
};

export default useIncomeStatement;
