/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import useInvoices from "../../../hooks/useInvoices";
import useSuppliers from "../../../hooks/useSuppliers";
import { apiConfig } from "../../../utils/axios";
import { formatCurrency, formatPaymentStatus } from "../../../utils/helpers";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddInvoice from "./AddInvoice";
import MakePayment from "./MakePayment";
import ViewInvoice from "./ViewInvoice";
import ViewTransactions from "./ViewTransactions";

const ListInvoices = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { getSupplierName } = useSuppliers();
  const { invoices, loading, refresh } = useInvoices();
  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Serial",
      selector: (row) => row.serial_no,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Client",
      selector: (row) => getSupplierName(row.client_id),
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 3,
      name: "Create date",
      selector: (row) => row.invoiced_on,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 4,
      name: "Due date",
      selector: (row) => row.due_date,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 5,
      name: "Grand Total",
      selector: (row) => formatCurrency(row.sub_total),
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 6,
      name: "Total Paid",
      selector: (row) =>
        formatCurrency(
          row?.payments
            ?.map((item: any) => item.total_paid)
            .reduce(
              (accumulator: any, element: any) =>
                Number(accumulator) + Number(element),
              0
            )
        ),
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 7,
      name: "Amount Due",
      selector: (row) =>
        formatCurrency(
          row.sub_total -
            row?.payments
              ?.map((item: any) => item.total_paid)
              .reduce(
                (accumulator: any, element: any) =>
                  Number(accumulator) + Number(element),
                0
              )
        ),
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 8,
      name: "Payment status",
      selector: (row) => (
        <span
          className={`badge ${
            formatPaymentStatus(row.payment_status).className
          }`}
        >
          {" "}
          <i
            className={`fa ${formatPaymentStatus(row.payment_status).icon}`}
          ></i>{" "}
          {formatPaymentStatus(row.payment_status).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className="row justify-content-between align-items-center">
          <button
            className="btn btn-sm btn-info"
            onClick={(e) => {
              e.stopPropagation();
              handleViewTransactionClick(row.id);
            }}
          >
            <i className="fa fa-list"></i>
          </button>
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`${apiConfig.baseURL}invoices/${row.id}/print/`}
            className="btn mx-1 d-block btn-sm btn-primary"
          >
            <i className="fa fa-print"></i>{" "}
          </a>
          {/* <button className='btn btn-sm btn-primary mx-1' onClick={(e) => {
            e.stopPropagation();
            handleViewClick(row.id);
          }}><i className='fa fa-eye'></i></button> */}
          {/* <button className='btn btn-sm btn-success ' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}><i className='fa fa-edit' ></i></button> */}

          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>

          {row.payment_status !== 1 && (
            <button
              className="btn btn-sm btn-warning"
              onClick={(e) => {
                e.stopPropagation();
                handleMakePaymentClick(row.id);
              }}
            >
              <i className="fa fa-money"></i>
            </button>
          )}
        </div>
      ),
    },
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleViewClick = (id: number) => {
    setOpenModal(4); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleViewTransactionClick = (id: number) => {
    setOpenModal(5); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleMakePaymentClick = (id: number) => {
    setOpenModal(6); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-invoice";
      modalTitle = "Edit Edit Invoice details";
      ModalToRender = AddInvoice;

      break;
    case 3:
      modalId = "delete-invoice";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    case 4:
      modalId = "view-invoice";
      modalTitle = "View Invoice Details";
      ModalToRender = ViewInvoice;
      break;
    case 5:
      modalId = "view-invoice-transactions";
      modalTitle = "View Invoice Payment history";
      ModalToRender = ViewTransactions;
      break;
    case 6:
      modalId = "make-payment";
      modalTitle = "Make Invoice Payment";
      ModalToRender = MakePayment;
      break;
    default:
      modalId = "add-new-announcement";
      modalTitle = "Add New Invoice";
      ModalToRender = AddInvoice;
      break;
  }

  return loading ? (
    <EllipsisLoader />
  ) : (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`invoices/${selectedId}/delete`}
            refresh={refresh}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(0);
                    }}
                  >
                    <i className="fa fa-plus mr-1"></i>Add New Invoice
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={invoices} title="" />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListInvoices;
