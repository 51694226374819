import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import { Link } from "react-router-dom";
import useBids from "../../../hooks/useBids";
import AddBid from "./AddBid";
import ViewQuotation from "../quotations/ViewQuotation";
import AcceptRequisition from "../requisition/AcceptRequisition";
import DeclineRequisition from "../requisition/DeclineRequisition";
import ReviewRequisition from "../requisition/ReviewRequisition";

const ListBids = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { loading, refresh, bids, selectedBid } = useBids();
  const [sortedBids, setSorteBids] = useState([])

  useEffect(()=>{
    if(bids.length > 0) {
      const sortedBids: any  = [...bids].sort((a: {id:number}, b: {id:number}) => b.id - a.id)
      setSorteBids(sortedBids)
    }
  }, [bids])

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 1,
      name: "Requisition Id",
      selector: (row) => row.requisition_id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Date",
      selector: (row) => row.date,
      reorder: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 3,
      name: "Status",
      selector: (row) => row.status_id,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 4,
      name: "Reference",
      selector: (row) => row.reference,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 5,
      name: "Supplier",
      selector: (row) => (row.supplier ? row.supplier.name : null),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      button: true,
      cell: (row) =>
        [3, "Declined"].includes(row.status) ? (
          <div className="row">
            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ) : row.status === "Approved" ? (
          <div className="row">
            <Link
              className="btn btn-sm btn-info mr-1"
              to={`/procurement/bids/view/${row.id}`}
            >
              <i className="fa fa-eye"></i>
            </Link>

            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ) : (
          <div className="row">
            <Link
              className="btn btn-sm btn-info mr-1"
              to={`/procurement/bids/view/${row.id}`}
            >
              <i className="fa fa-eye"></i>
            </Link>

            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ),
    },
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  // const reviewQuotation = (id: number) => {
  //   setOpenModal(1); // this opens the edit modal
  //   setSelectedId(id);
  //   setIsOpen(true);
  // };

  // const acceptQuotation = (id: number) => {
  //   setOpenModal(2); // this opens the edit modal
  //   setSelectedId(id);
  //   setIsOpen(true);
  // };

  // const declineQuotation = (id: number) => {
  //   setOpenModal(3); // this opens the edit modal
  //   setSelectedId(id);
  //   setIsOpen(true);
  // };

  const handleDeleteClick = (id: number) => {
    setOpenModal(4); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const viewQuotation = (id: number) => {
    setOpenModal(5); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 1:
      modalId = "review-requisition";
      modalTitle = "Submit your review";
      ModalToRender = ReviewRequisition;
      break;
    case 2:
      modalId = "accept-requisition";
      modalTitle = "Approve requisition";
      ModalToRender = AcceptRequisition;

      break;
    case 3:
      modalId = "decline-requisition";
      modalTitle = "Decline Requisition note";
      ModalToRender = DeclineRequisition;

      break;
    case 4:
      modalId = "delete-bid-stage";
      modalTitle = "Confirm Bid Stage Deletion";
      ModalToRender = ConfirmDelete;
      break;
    case 5:
      modalId = "view-quotation";
      modalTitle = "";
      ModalToRender = ViewQuotation;
      break;
    default:
      modalId = "add-new-bid";
      modalTitle = "Add New Bid";
      ModalToRender = AddBid;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`bid-analysis/${selectedId}`}
            refresh={refresh}
            onCancel={handleModalClick}
          />
        }
      />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                      setOpenModal(0);
                    }}
                  >
                    <i className="fa fa-plus mr-1"></i>Add New Bid
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={sortedBids ?? []} title="" />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListBids;
