import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { EllipsisLoader } from "../../global/MyLoaders";
import useSchedules from "../../../hooks/useSchedules";
import moment from "moment";
import { formatCurrency } from "../../../utils/helpers";
import './bs.css'
import useBalanceSheet from "../../../hooks/useBalanceSheet";
import Breadcrumbs from "../../../parts/Breadcrumbs";
import DashboardWrapper from "../../DashboardWrapper";

interface Structure {
  id: number,
  cat_name: string,
  categories: []
}

interface categoryStructure {
  sub_cat_name: string;
  sub_cat_code: string;
  ledgers: [ledger]
}

interface ledger {
  opening_balance: number;
}

interface bsSections {
  categories: categoryStructure[]
}

interface dates {
    start_date: string;
    end_date: string;
    prev_start_date: string;
    prev_end_date: string;
}
const NewBalanceSheetComparisonReport = () => {
  const { bsComparisonReport, getBSComparisonReport, loading } = useSchedules();
//   const {balanceSheet, getBalanceSheet, loading} = useBalanceSheet()
  const [assets, setAssets] = useState([])
  const [liabilities, setLiabilities] = useState([])
  const [equity , setEquity] = useState([])
  const [dates, setDates] = useState<dates>()

  useEffect(()=> getBSComparisonReport(), [])

  useEffect(()=> {
    if(bsComparisonReport.length > 0){
        const assetsData = bsComparisonReport.filter((item: Structure) => item.cat_name === 'RASILIMALI/ASSETS');
        const liabilitiesData = bsComparisonReport.filter((item: Structure) => item.cat_name === 'MTAJI NA DHIMA / CAPITAL AND LIABILITIES');
        const equityData = bsComparisonReport.filter((item: Structure) => item.cat_name === 'Equity');
        console.log('bscomparison', bsComparisonReport)
        // setDates(bsComparisonReport.date)
        setAssets(assetsData);
        setLiabilities(liabilitiesData);
        setEquity(equityData);
    }
    // if(bsComparisonReport.length > 0){
    //   const assetsData = balanceSheet.filter((item: Structure) => item.cat_name === 'RASILIMALI/ASSETS');
    //   
    //   

    //   // Update state with filtered data
    //   setAssets(assetsData);
    //   setLiabilities(liabilitiesData);
    //   setEquity(equityData);
   // }
  }, [bsComparisonReport])


  //useEffect(() => getComparisonReport(4), []);

  
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const totalLiabilities = liabilities.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.current_balance || 0);
      }, 0);
    }, 0);
  }, 0);
  
  // Calculate total equity
  const totalEquity = equity.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.current_balance || 0);
      }, 0);
    }, 0);
  }, 0);


  const prevTotalLiabilities = liabilities.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.previous_balance || 0);
      }, 0);
    }, 0);
  }, 0);
  
  // Calculate previous total equity
  const prevTotalEquity = equity.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.previous_balance || 0);
      }, 0);
    }, 0);
  }, 0);

  return (loading && !bsComparisonReport) ? (
    <EllipsisLoader />
  ) : (
    <DashboardWrapper>
    <>
      <Breadcrumbs
        title="Balance Sheet Comparison Report"
      />
        <div className="col-xl-12 mb-30">
        <div className="row mx-0 mb-3 justify-content-end">
          <div className="col-xs-12 col-md-3">
            <div className="row justify-content-end">
              <label>Export</label>
              <button
                onClick={handlePrint}
                className="ml-3 btn btn-sm btn-secondary"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>
        </div>
          <table className="table" ref={componentRef}>
          <thead>
            <tr className="mb-2">
              <th className="bg-info text-center text-white py-2" colSpan={4}>
                Balance Sheet Comparison Report
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Code</th>
              <th>
                {/* {dates && dates.start_date} / {dates && dates.end_date} */} May-2023 / May-2024
              </th>
              <th>
                {/* {dates && dates.prev_start_date} / {dates && dates.prev_end_date} */} May-2022 / May-2023
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-strong bs-header">Rasilimali / Assets</td>
            </tr>
            {
              assets.map((asset: bsSections) => (
                asset.categories.map((cat: categoryStructure, i: number) => {
                  // Filter out ledgers with current_balance equal to 0
                  const filteredLedgers = cat.ledgers.filter((ledger: any) => ledger.current_balance !== 0);

                  // If there are no ledgers with current_balance not equal to 0, skip this category
                  if (filteredLedgers.length === 0) return null;

                  return (
                    <tr key={i + 1}>
                      <td>{cat.sub_cat_name}</td>
                      <td>{cat.sub_cat_code}</td>
                      <td>
                        {filteredLedgers.reduce((total: number, ledger: any) => total + (ledger.current_balance || 0), 0)}
                      </td>
                      <td>
                        {filteredLedgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance || 0), 0)}
                      </td>
                    </tr>
                  );
                })
              ))
            }
            <tr>
              <td className="font-strong bs-subheader">Jumla Ya Rasilimali</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
              {assets && assets.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance || 0), 0);
                  total += categoryTotal; 
                });
                return total; // Return the total for each eq
              })}
              </td>
              <td className="font-strong bs-subheader">
                {assets && assets.map((eq: bsSections) => {
                  let total: number = 0; // Initialize total
                  eq.categories.forEach((cat: categoryStructure) => {
                    const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance || 0), 0);
                    total += categoryTotal; 
                  });
                  return total; // Return the total for each eq
                })}
              </td>
            </tr>
            

            <tr>
              <td className="font-strong bs-header">Mtaji na Dhima / Capital and Liabilities</td>
            </tr>
            <tr>
              <td className="font-strong bs-subheader">Mtaji / Capital</td>
            </tr>
            {
              equity.map((eq: bsSections) => (
                eq.categories.map((cat: categoryStructure, i: number) => {
                  // Filter out ledgers with current_balance equal to 0
                  const filteredLedgers = cat.ledgers.filter((ledger: any) => ledger.current_balance !== 0);

                  // If there are no ledgers with current_balance not equal to 0, skip this category
                  if (filteredLedgers.length === 0) return null;

                  return (
                    <tr key={i + 1}>
                      <td>{cat.sub_cat_name}</td>
                      <td>{cat.sub_cat_code}</td>
                      <td>
                        {filteredLedgers.reduce((total: number, ledger: any) => total + (ledger.current_balance || 0), 0)}
                      </td>
                      <td>
                        {filteredLedgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance || 0), 0)}
                      </td>
                    </tr>
                  );
                })
              ))
            }
            <tr>
              <td className="font-strong bs-subheader">Jumla Ya Mtaji</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
              {equity && equity.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance || 0), 0);
                  total += categoryTotal; // Add categoryTotal to total
                });
                return total; // Return the total for each eq
              })}
              </td>
              <td className="font-strong bs-subheader">
              {equity && equity.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance || 0), 0);
                  total += categoryTotal; // Add categoryTotal to total
                });
                return total; // Return the total for each eq
              })}
              </td>
            </tr>
            <tr>
              <td className="font-strong bs-subheader">Dhima za muda mrefu / Liabilities</td>
            </tr>
            {
              liabilities.map((liability: bsSections) => (
                liability.categories.map((cat: categoryStructure, i: number) => {
                  // Filter out ledgers with current_balance equal to 0
                  const filteredLedgers = cat.ledgers.filter((ledger: any) => ledger.current_balance !== 0);

                  // If there are no ledgers with current_balance not equal to 0, skip this category
                  if (filteredLedgers.length === 0) return null;

                  return (
                    <tr key={i + 1}>
                      <td>{cat.sub_cat_name}</td>
                      <td>{cat.sub_cat_code}</td>
                      <td>
                        {filteredLedgers.reduce((total: number, ledger: any) => total + (ledger.current_balance || 0), 0)}
                      </td>
                      <td>
                        {filteredLedgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance || 0), 0)}
                      </td>
                    </tr>
                  );
                })
              ))
            }
            <tr>
              <td className="font-strong bs-subheader">Jumla Ya Dhima za muda mrefu</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
                {liabilities && liabilities.map((eq: bsSections) => {
                  let total: number = 0; // Initialize total
                  eq.categories.forEach((cat: categoryStructure) => {
                    const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance || 0), 0);
                    total += categoryTotal; // Add categoryTotal to total
                  });
                  return total; // Return the total for each eq
                })}
              </td>
              <td className="font-strong bs-subheader">
                {liabilities && liabilities.map((eq: bsSections) => {
                  let total: number = 0; // Initialize total
                  eq.categories.forEach((cat: categoryStructure) => {
                    const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance || 0), 0);
                    total += categoryTotal; // Add categoryTotal to total
                  });
                  return total; // Return the total for each eq
                })}
              </td>
            </tr>
            <tr>
              <td className="font-strong bs-subheader">Jumla Ya Mtaji Na Dhima</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
                  { totalLiabilities + totalEquity}
              </td>
              <td className="font-strong bs-subheader">
                  {prevTotalLiabilities + prevTotalEquity}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  </DashboardWrapper>
  );
};

export default NewBalanceSheetComparisonReport;
