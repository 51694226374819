import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import { setBsComparisonReport, setComparisonReport, setSchedules } from "../redux/reducer/accounting";

const useSchedules = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { schedules, comparisonReport, bsComparisonReport } = useSelector(
    (state: RootState) => state.accounting
  );

  const getSchedules = () => {
    doGet(`reports/comparison-report-full`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSchedules(response.data.data));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getBSComparisonReport = () => {
    doGet(`reports/comparison_balance_sheet`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setBsComparisonReport(response.data.data));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getComparisonReport = (categoryId: number) => {
    doGet(`reports/comparison-report/${categoryId}`)
      .then((response) => {
        //console.log('res1', response)
        if (response.status === 200) {
          //console.log('res2', response.data.data)
          dispatch(setComparisonReport(response.data.data));
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    schedules,
    comparisonReport,
    getSchedules,
    getComparisonReport,
    getBSComparisonReport,
    bsComparisonReport
  };
};

export default useSchedules;
