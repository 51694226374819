import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import useAccounts from "../../../hooks/useAccounts";
import { EllipsisLoader } from "../../global/MyLoaders";
import useSchedules from "../../../hooks/useSchedules";
import moment from "moment";
import { formatCurrency } from "../../../utils/helpers";
import useIncomeStatement from "../../../hooks/useIncomeStatement";
import './bs.css'

interface Structure {
  id: number,
  cat_name: string,
  categories: [categoryStructure]
}

interface categoryStructure {
  sub_cat_name: string;
  sub_cat_code: string;
  ledgers: [ledger]
}

interface ledger {
  id: number;
  balance: {total: number} | null;
}

interface bsSections {
  categories: categoryStructure[]
}

const IncomeStatementComparison = () => {
  const { comparisonReport } = useSchedules();
  const { incomeStatement, getIncomeStatement, loading} = useIncomeStatement();
  const [income, setIncome] = useState<Structure[]>([])
  const [expense, setExpense] = useState([])

  useEffect(() => getIncomeStatement(), []);


  useEffect(()=>{
    if(incomeStatement){
      const income = incomeStatement.filter((item: Structure) => item.cat_name === 'MAPATO/INCOME');
      const expenses = incomeStatement.filter((item: Structure) => item.cat_name === 'MATUMIZI/EXEPENSES');
      if(income.length > 0){
        console.log('tz', income[0].cat_name)
      }
      setIncome(income)
      setExpense(expenses)
    }
  },[incomeStatement])

  const totalIncome = income.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.current_balance ? ledger.current_balance || 0: 0);
      }, 0);
    }, 0);
  }, 0);

  const totalExpenses = expense.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.current_balance ? ledger.current_balance || 0: 0);
      }, 0);
    }, 0);
  }, 0);

  const prevTotalIncome = income.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.current_balance ? ledger.previous_balance || 0: 0);
      }, 0);
    }, 0);
  }, 0);

  const prevTotalExpenses = expense.reduce((total: number, eq: bsSections) => {
    return total + eq.categories.reduce((catTotal: number, cat: categoryStructure) => {
      return catTotal + cat.ledgers.reduce((ledgerTotal: number, ledger: any) => {
        return ledgerTotal + (ledger.current_balance ? ledger.previous_balance || 0: 0);
      }, 0);
    }, 0);
  }, 0);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (loading && !incomeStatement) ? (
    <EllipsisLoader />
  ) : (
    <>
      <div className="col-xl-12 mb-30">
        <div className="row mx-0 mb-3 justify-content-end">
          <div className="col-xs-12 col-md-3">
            <div className="row justify-content-end">
              <label>Export</label>
              <button
                onClick={handlePrint}
                className="ml-3 btn btn-sm btn-secondary"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>
        </div>
        <table className="table" ref={componentRef}>
          <thead>
            <tr className="mb-2">
              <th className="bg-info text-center text-white py-2" colSpan={4}>
                Statement Of Comprehensive Income Comparison Report
              </th>
            </tr>
            <tr>
              <th>Item</th>
              <th>Code</th>
              <th>
                {moment(comparisonReport["date"]?.start_date).format("ll")} -{" "}
                {moment(comparisonReport["date"]?.end_date).format("ll")}
              </th>
              <th>
                {moment(comparisonReport["date"]?.start_date).format("ll")} -{" "}
                {moment(comparisonReport["date"]?.end_date).format("ll")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-strong bs-header">MAPATO/INCOME</td>
            </tr>
            {income.length > 0 &&
              income.map((eq: bsSections)=>(
                  eq.categories.map((cat: categoryStructure,i: number)=>(
                    <>
                      <tr key={i+1}>
                        <td>{cat.sub_cat_name}</td>
                        <td>{cat.sub_cat_code}</td>
                        <td>
                        {cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance ? ledger.current_balance || 0 : 0), 0)}
                        </td>
                        <td>
                        {cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance ? ledger.previous_balance || 0 : 0), 0)}
                        </td>
                      </tr>
                    </>
                  ))
              ))
            }
            <tr>
              <td className="font-strong bs-subheader">Total Income</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
              {income && income.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance ? ledger.current_balance || 0 : 0), 0);
                  total += categoryTotal; // Add categoryTotal to total
                });
                return total; // Return the total for each eq
              })}
              </td>
              <td className="font-strong bs-subheader">
              {income && income.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance ? ledger.previous_balance || 0 : 0), 0);
                  total += categoryTotal; // Add categoryTotal to total
                });
                return total; // Return the total for each eq
              })}
              </td>
            </tr>
            <tr>
              <td className="font-strong bs-header">MATUMIZI/EXPENSES</td>
            </tr>
            {
              expense.map((eq: bsSections)=>(
                  eq.categories.map((cat: categoryStructure,i: number)=>(
                    <>
                      <tr key={i+1}>
                        <td>{cat.sub_cat_name}</td>
                        <td>{cat.sub_cat_code}</td>
                        <td>
                        {cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance ? ledger.current_balance || 0 : 0), 0)}
                        </td>
                        <td>
                        {cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance ? ledger.previous_balance || 0 : 0), 0)}
                        </td>
                      </tr>
                    </>
                  ))
              ))
            }
             <tr>
              <td className="font-strong bs-subheader">Total Expenses</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
              {expense && expense.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.current_balance ? ledger.current_balance || 0 : 0), 0);
                  total += categoryTotal; // Add categoryTotal to total
                });
                return total; // Return the total for each eq
              })}
              </td>
              <td className="font-strong bs-subheader">
              {expense && expense.map((eq: bsSections) => {
                let total: number = 0; // Initialize total
                eq.categories.forEach((cat: categoryStructure) => {
                  const categoryTotal = cat.ledgers.reduce((total: number, ledger: any) => total + (ledger.previous_balance ? ledger.previous_balance || 0 : 0), 0);
                  total += categoryTotal; // Add categoryTotal to total
                });
                return total; // Return the total for each eq
              })}
              </td>
            </tr>
            <tr>
              <td className="font-strong bs-subheader">Net Profit/Loss</td>
              <td className="font-strong bs-subheader"></td>
              <td className="font-strong bs-subheader">
              {totalIncome - totalExpenses}
              </td>
              <td className="font-strong bs-subheader">
              {prevTotalIncome - prevTotalExpenses}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IncomeStatementComparison